import { routerReducer } from 'connected-next-router';
import { HYDRATE } from 'next-redux-wrapper';
import { combineReducers } from 'redux';
// All reducers
import cmsGlobalData from './cmsGlobalData';
import common from './common';
import exitIntentModal from './exitIntentModal';
import filter from './filter';
import footer from './footer';
import global from './global';
import menuBar from './menuBar';
import profile from './profile';
import user from './user';
import userModal from './userModal';

const combinedReducer = combineReducers({
	common: common.reducer,
	footer: footer.reducer,
	menuBar: menuBar.reducer,
	global: global.reducer,
	userModal: userModal.reducer,
	user: user.reducer,
	cmsGlobalData: cmsGlobalData.reducer,
	router: routerReducer,
	filter: filter.reducer,
	exitIntentModal: exitIntentModal.reducer,
	profile: profile.reducer,
});

// Using next-redux-wrapper's initStore
const masterReducer = (state, action) => {
	if (action.type === HYDRATE) {
		const nextState = {
			...state, // use previous state
			...action.payload, // apply delta from hydration
		};
		if (typeof window !== 'undefined' && state) {
			if (state.router) {
				// preserve router value on client side navigation
				nextState.router = state.router;
			}
			if (state.user) {
				nextState.user = state.user;
			}
			if (state.profile) {
				nextState.profile = state.profile;
			}
		}
		return nextState;
	} else {
		return combinedReducer(state, action);
	}
};

export default masterReducer;

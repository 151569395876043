import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

// utils
import getPlatform from '@/utils/getPlatform';

// constants
import constants from '@/constants';
import { levelAndType } from '@/utils/constants/program';
import { getCompassCourseFinderURL } from '@/utils/redirect-to-platform';
import { defaultCoverImage, programTypes, textValues } from './constants';
const isPlatformPartner = getPlatform() === 'partner';

export const getBannerImages = (images, alternativeText) => {
	const titleImages = [];

	if (get(images, 'cover_image.original'))
		titleImages.push({ url: images.cover_image.original, alternativeText });

	if (!isEmpty(get(images, 'gallery'))) {
		images.gallery.forEach((image) => {
			titleImages.push({ url: image.original, alternativeText });
		});
	}

	return titleImages.length === 0
		? [{ url: defaultCoverImage, alternativeText }]
		: titleImages;
};

export const getBannerSubTitle = (data) => {
	const subTitleTexts = [];

	if (get(data, 'location.state') || get(data, 'location.city'))
		subTitleTexts.push(data.location.state || data.location.city);

	if (get(data, 'location.country')) subTitleTexts.push(data.location.country);

	if (data.type) subTitleTexts.push(data.type);

	if (data.founded_year) subTitleTexts.push(`Estd. ${data.founded_year}`);

	return subTitleTexts.length === 0 ? null : subTitleTexts.join(' | ');
};

export const getBannerStats = (data) => {
	const stats = [];

	if (get(data, 'rankings[0].country_rank')) {
		stats.push({
			title: `#${data.rankings[0].country_rank}`,
			subTitle: 'Rank',
			maxTextLength: 10,
		});
	}

	if (!isEmpty(data.intake_months))
		stats.push({
			title: data.intake_months.join('/'),
			subTitle: 'Intake',
			extraDetails: data.intake_months,
			maxTextLength: 11,
		});

	if (get(data, 'costs.living_cost')) {
		const livingCost = data.costs.currency
			? `${data.costs.currency} ${data.costs.living_cost}`
			: `${data.costs.living_cost}`;

		stats.push({
			title: livingCost,
			subTitle: 'Cost of Living',
			maxTextLength: 10,
		});
	}

	if (stats.length === 3) return stats;

	if (get(data, 'costs.avg_tuition')) {
		const tuitionFee = data.costs.currency
			? `${data.costs.currency} ${data.costs.avg_tuition}`
			: `${data.costs.avg_tuition}`;

		stats.push({
			title: tuitionFee,
			subTitle: 'Tuition Fee',
			maxTextLength: 10,
		});
	}

	return stats;
};

export const getBannerButton = (universityId) => {
	let link;
	if (isPlatformPartner) {
		link = `${constants.businessPlatformUrl}/signup?partnerNew=true`;
	} else {
		link = getCompassCourseFinderURL({ university_ids: universityId });
	}

	return { label: 'Apply Now', variant: 'contained', link };
};

export const getProgramCardData = (
	universityId,
	universityLogo,
	data,
	clientType
) => {
	let link,
		buttonLabel = textValues.buttonLabel;
	if (isPlatformPartner) {
		link = `${constants.businessPlatformUrl}/signup?partnerNew=true`;
	} else if (clientType == textValues.nonPartnerClientType) {
		link = constants.studentPlatformUrl;
		buttonLabel = textValues.buttonLabelNonPartner;
	} else {
		link = getCompassCourseFinderURL(
			{ university_ids: universityId, level_and_type: data.level_and_type },
			{ q: data.name }
		);
	}

	let tuitionFee = get(data, 'costs.tuition_fee');
	if (tuitionFee)
		tuitionFee = get(data, 'costs.currency')
			? `${data.costs.currency} ${tuitionFee}`
			: `${tuitionFee}`;
	else tuitionFee = '-';

	let applicationFee = get(data, 'costs.application_fee');
	if (applicationFee)
		applicationFee = get(data, 'costs.currency')
			? `${data.costs.currency} ${applicationFee}`
			: `${applicationFee}`;
	else applicationFee = '-';

	const intakeMonths = isEmpty(data.intake_months) ? ['-'] : data.intake_months;

	return {
		id: data._id,
		name: data.name,
		slug: data.slug,
		logo: universityLogo,
		link,
		levelAndType: data.level_and_type,
		length: data.length || '-',
		intakeMonths,
		tuitionFee,
		applicationFee,
		buttonLabel,
	};
};

export const getProgramLevelFilterOptions = (programs) => {
	let currentProgramLevelKey;
	const programCountMap = programs.reduce((programCountMap, program) => {
			currentProgramLevelKey = program.level_and_type;
			if (!(currentProgramLevelKey in program)) {
				programCountMap[currentProgramLevelKey] = 0;
			}
			++programCountMap[currentProgramLevelKey];
			return programCountMap;
		}, {}),
		programLevelFilterOptions = [];

	if (programCountMap[levelAndType['UG Degree/Bachelors']] !== 0) {
		programLevelFilterOptions.push({
			label: 'Bachelor',
			value: levelAndType['UG Degree/Bachelors'],
		});
	}
	if (programCountMap[levelAndType['PG Degree/Masters']] !== 0) {
		programLevelFilterOptions.push({
			label: 'Master',
			value: levelAndType['PG Degree/Masters'],
		});
	}

	if (programLevelFilterOptions.length !== 2) {
		return [];
	}

	programLevelFilterOptions.push({ label: 'All', value: 'all' });
	return programLevelFilterOptions;
};

export const getProgramsByLevel = (programs, levelFilter, count = 6) => {
	const filteredPrograms = [];
	let program;

	for (let i = 0; i < programs.length; i++) {
		program = programs[i];
		if (levelFilter !== 'all' && program.level_and_type !== levelFilter)
			continue;

		filteredPrograms.push(program);
		if (filteredPrograms.length >= count) break;
	}

	return filteredPrograms;
};

export const googleMapsURLToEmbedURL = (universityName, universityLocation) => {
	let q = universityName;

	if (get(universityLocation, 'city')) q += `,${universityLocation.city}`;
	if (get(universityLocation, 'state')) q += `,${universityLocation.state}`;
	if (get(universityLocation, 'country')) q += `,${universityLocation.country}`;
	q = q.replace(/ +/g, '+');

	return `${constants.googleMapsApiUrl}q=${q}`;
};

export const getExamAcceptedScores = (examScores = {}) => {
	let scores = {},
		examAcceptedScores = {};

	for (const type of programTypes) {
		if (isEmpty(examScores[type])) continue;

		scores = examScores[type];
		break;
	}

	for (const key in scores) {
		const score = scores[key];
		if (typeof score === 'number') examAcceptedScores[key] = score;
		else if (get(score, 'overall')) examAcceptedScores[key] = score.overall;
	}

	return examAcceptedScores;
};

export const getUniversityCardData = (data) => {
	let intakeMonths = get(data, 'intake_months', []);
	intakeMonths = intakeMonths.length === 0 ? null : intakeMonths.join('/');

	let rank = get(data, 'rankings[0].country_rank', '').toString();
	let ranking = null;
	if (rank) {
		ranking = {
			rank: `#${rank}`,
			source: get(data, 'rankings[0].source_name', ''),
		};
	}

	let courseCount = get(data, 'total_programs_count', '').toString();
	courseCount = courseCount ? `${data.total_programs_count} Courses` : null;

	return {
		name: data.name,
		ranking,
		intakeMonths,
		courseCount,
		logo: {
			url: get(data, 'images.logo.original', ''),
			alternativeText: data.name,
		},
		image: {
			url: get(data, 'images.cover_image.original', defaultCoverImage),
			alternativeText: data.name,
		},
		link: `/course-finder/universities/${data.slug}`,
	};
};

import PropTypes from 'prop-types';

// material
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Collapse, List, ListItem, ListItemText } from '@mui/material';

// style
import useStyles from './style';

function LoginExpandingList({ open, subOptions, label }) {
	const { classes } = useStyles();
	let [listOpen, setListOpen] = React.useState(open);
	return (
		<>
			<ListItem button onClick={() => setListOpen(!listOpen)}>
				<ListItemText primary={label} />
				{listOpen ? <ExpandLess /> : <ExpandMore />}
			</ListItem>
			<Collapse in={listOpen} timeout="auto" unmountOnExit>
				<List component="div" disablePadding>
					{subOptions.map((subOption, index) => (
						<ListItem
							button
							key={index}
							className={classes.nested}
							onClick={subOption.onClick}
						>
							<ListItemText primary={subOption.label} />
						</ListItem>
					))}
				</List>
			</Collapse>
		</>
	);
}

export default React.memo(LoginExpandingList);

LoginExpandingList.propTypes = {
	open: PropTypes.bool,
	subOptions: PropTypes.array.isRequired,
	label: PropTypes.string.isRequired,
};

LoginExpandingList.defaultProps = {
	open: false,
};

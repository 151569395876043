import { keyframes } from 'tss-react';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
	spinnerContainer: {
		display: 'inline-block',
		width: '70px',
		height: '70px',
		border: '3px solid rgba(195, 195, 195, 0.6)',
		borderRadius: '50%',
		borderTopColor: '#636767',
		animation: `${keyframes`
			to {
			  -webkit-transform: rotate(360deg);
			}
		  `} 1s ease-in-out infinite`,
	},
}));

export default useStyles;

import { useSelector } from 'react-redux';

// constants
import constants from '@/constants';

// components
import DesktopMenuBar from '@/components/shared/menuBar/partials/desktopMenuBar';
import MobileMenuBar from '@/components/shared/menuBar/partials/mobileMenuBar';

export default function MenuBar(props) {
	const isPartnerPlatform = constants.platform === 'partner';
	const isDesktop = useSelector((state) => state.common.isDesktop);

	if (isDesktop) {
		return (
			<DesktopMenuBar
				{...props}
				logo={{
					...props.logo,
					height: isPartnerPlatform ? '72px' : '62px',
					width: isPartnerPlatform ? '232px' : '200px',
				}}
			/>
		);
	}

	return (
		<MobileMenuBar
			{...props}
			logo={{
				...props.logo,
				height: isPartnerPlatform ? '56px' : '46px',
				width: isPartnerPlatform ? '180px' : '150px',
			}}
		/>
	);
}

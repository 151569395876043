import constants from '@/constants';
import formInstance from './axios-instances/form';
import uploadInstance from './axios-instances/upload-instance';

export function signRequest(data) {
	return uploadInstance.post(constants.fileUploadURL, data);
}

export function registerForEvent(url, data) {
	return formInstance.post(url, data);
}

import PropTypes from 'prop-types';
import { Link as ScrollLink } from 'react-scroll';

// material
import { KeyboardArrowUp } from '@mui/icons-material';
import { Fab, useScrollTrigger, Zoom } from '@mui/material';

// utils
import * as eventActions from '@/utils/analytics';

// style
import useStyles from './style';

export default function ScrollToId({
	triggerThreshold,
	analyticsProps,
	scrollToId,
	...props
}) {
	const { classes } = useStyles(props);

	const trigger = useScrollTrigger({
		disableHysteresis: true,
		threshold: triggerThreshold,
	});

	const onClickButton = () => {
		eventActions.trackEvent(analyticsProps.eventName || 'BUTTON_CLICKED', {
			eventCategory: 'USER_EVENT',
			eventLabel: 'UP_ARROW',
			eventAction: 'CLICKED',
			...analyticsProps,
		});
	};

	return (
		<ScrollLink
			to={scrollToId}
			spy={true}
			smooth={true}
			duration={300}
			onClick={onClickButton}
			offset={props.offset || 0}
		>
			<Zoom in={trigger}>
				<Fab
					size={'medium'}
					className={classes.scrollButton}
					variant={'round'}
					disableRipple={true}
					disableFocusRipple={true}
				>
					<KeyboardArrowUp />
				</Fab>
			</Zoom>
		</ScrollLink>
	);
}

ScrollToId.propTypes = {
	scrollToId: PropTypes.string.isRequired,
	triggerThreshold: PropTypes.number,
	analyticsProps: PropTypes.object,
	buttonColor: PropTypes.string,
	widgetPosition: PropTypes.number,
};

ScrollToId.defaultProps = {
	analyticsProps: {},
	widgetPosition: 4,
};

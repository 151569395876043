export const componentTypes = {
	CategorizedModuleSection: 'shared.categorized-module-section',
	CommentSection: 'shared.comment-section',
	ContactAndLocationSection: 'shared.contact-and-location-section',
	ContentSection: 'shared.content',
	CounterSection: 'shared.counter-section',
	DownloadAppHighlight: 'shared.download-app-highlight',
	FAQSection: 'shared.faq-section',
	FormSection: 'shared.form-section',
	GenericTableSection: 'generic-table-section',
	HighlightSection: 'shared.highlight-section',
	HighlightServiceSection: 'shared.highlight-service-section',
	HighlightStepsSection: 'shared.highlight-steps-section',
	IFrameSection: 'shared.i-frame-section',
	ImageGallerySection: 'shared.image-gallery-section',
	ListingSection: 'shared.listing-section',
	LocationSection: 'contact-us-page.location',
	LoginCTA: 'shared.login-cta-section',
	NavigationSection: 'shared.navigation-section',
	OverviewSection: 'shared.overview-section',
	PerksAndBenefitsSection: 'shared.perks-and-benefits-section',
	PlanComparisonSection: 'shared.plan-comparison-section',
	PlanDetailSection: 'shared.plan-detail-section',
	PlanListSection: 'shared.plan-list-section',
	RecentlyAddedSection: 'shared.recently-added-section',
	InfoNote: 'shared.info-note',
	LiveCounsellingSection: 'shared.live-counselling-plug-component',
	PlanOfferingSection: 'shared.plan-offering-section',
	ServiceSection: 'shared.services-section',
	TableSection: 'shared.table-section',
	TestimonialSection: 'shared.testimonial-section',
	Timeline: 'shared.timeline',
	TimeTableSection: 'shared.time-table-section',
	VideoGallerySection: 'shared.video-gallery-section',
	VideoSection: 'shared.video-section',
	WalkthroughSection: 'shared.walkthrough-section',
	AboutUsPageBannerSection: 'about-us-page.about-us-page-banner-section',
	CareerPageBannerSection: 'career-page.career-page-banner-section',
	CareerPageFormSection: 'career-page.career-page-form-section',
	EventPageBannerSection: 'event-page.event-page-banner-section',
	EventDetailPageBannerSection: 'event-page.event-detail-page-banner-section',
	EventGroupBannerSection: 'event-page.event-group-banner-section',
	HomePageBannerSection: 'home-page.home-page-banner-section',
	LandingPageBannerSection: 'landing-page.landing-page-banner-section',
	ProductPageBannerSection: 'product-page.product-page-banner-section',
	RedirectionSection: 'shared.redirection-section',
	ScholarshipDetailBannerSection:
		'scholarship-page.scholarship-page-detail-banner-section',
	ScholarshipDataList: 'scholarship-page.scholarship-section',
	ThankYouPageMainSection: 'thank-you-page.thank-you-section',
	UniversityBannerSection: 'university-page.university-page-banner-section',
	UniversityDataListSection: 'univserity-data-list-section',
	UniversityDetailBannerSection:
		'university-page.university-detail-page-banner-section',
	BlogPageSideBarSection: 'blog-page.side-bar-section',
};

export const FOOTER_WRAPPER_ID = 'FOOTER_WRAPPER';

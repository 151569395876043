import get from 'lodash/get';

// API
import { getCMSData } from '@/api';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	loading: false,
	data: null,
	error: null,
};

export const footer = createSlice({
	name: 'footer',
	initialState,
	reducers: {
		getFooterProps(state) {
			state.loading = true;
		},
		getFooterPropsSuccess: {
			reducer: (state, { payload }) => {
				state.loading = false;
				state.data = payload;
			},
			prepare: (data) => {
				return {
					payload: {
						...data,
						images: (get(data, 'images.data') || []).map((image) => ({
							url: get(image, 'attributes.url', ''),
							alternativeText: get(image, 'attributes.alternativeText', ''),
						})),
						socialIconActions:
							(data.socialIconActions &&
								data.socialIconActions.data &&
								data.socialIconActions.data.map((iconAction) => ({
									...iconAction.attributes,
								}))) ||
							[],
						actions:
							(data.actions &&
								data.actions.map((action) => {
									const subActions =
										(action.subActions &&
											action.subActions.data &&
											action.subActions.data.map(
												(subAction) => subAction.attributes
											)) ||
										[];
									return { ...action, subActions };
								})) ||
							[],
					},
				};
			},
		},
		getFooterPropsFailure(state, { payload }) {
			state.loading = false;
			state.error = payload;
		},
	},
});

const { getFooterProps, getFooterPropsSuccess, getFooterPropsFailure } =
	footer.actions;

function getFooterPropsAction() {
	return (dispatch) => {
		dispatch(getFooterProps());
		return getCMSData('footer', { populate: 'deep' })
			.then((response) => {
				response.data &&
					dispatch(getFooterPropsSuccess(response.data.data.attributes));
			})
			.catch((err) => {
				dispatch(getFooterPropsFailure(err));
			});
	};
}

export default footer;
export { getFooterPropsAction };

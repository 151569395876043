const defaultConfig = require('./default');
module.exports = {
	...defaultConfig,
	google_tag_manager_args: {
		gtmId: 'GTM-MB9PGHD',
		auth: 'v0x6ggH8eATIbqOh5xHKLg',
		preview: 'env-1',
	},
	SENTRY_DSN:
		'https://44c9e7fc4dcb44beb9e108a0ea6f6388@o409291.ingest.sentry.io/5497407',
	awsConfig: {
		awsKey: 'AKIA4KCCKZYLKVL63MFF',
		bucket: 'azent-documents',
		region: 'ap-south-1',
		s3BucketBaseUrl: 'https://azent-documents.s3.ap-south-1.amazonaws.com',
	},
	fileApi: 'https://api.azent.com/',
	fileUploadURL: '/aws-request-sign-awssigningrequest-13W23EBGNMGJV',
	seo: {
		...defaultConfig.seo,
		sitemapBaseUrl: 'https://www.azent.com',
		policies: [{ userAgent: '*', allow: '/' }],
		index: true,
		ampBaseUrl: 'https://amp.azent.com',
	},
	cookie: {
		domain: '.azent.com',
		path: '/',
		maxAge: 2629746,
		authAccessToken: 'azentUserData.production',
		referralCodeCookie: 'AzentReferralCode.production',
		formData: 'azentformData.production',
		redirect: 'azentUrlData.production',
		kioskCentre: 'azentKioskData.production',
	},
	cmsApi: 'https://cms-service-v2.azent.com/v1',
	cmsApiV1: 'https://cms-service.azent.com',
	authApi: 'https://api.azent.com',
	dataApi: 'https://api.azent.com/v1',
	eventsApi: 'https://events-api.azent.com/v1',
	lmsApi: 'https://lms-api.azent.com/v1',
	studentServiceApi: 'https://student-api.azent.com/v1',
	studentPlatformUrl: 'https://compass.azent.com/',
	businessPlatformUrl: 'https://partners.admissionbox.com',
	ieltsWebsiteUrl: 'https://testprep.azent.com',
};

import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

// actions
import { goToRoute } from '@/store/actions';

// material
import {
	Box,
	Button,
	ClickAwayListener,
	Grow,
	MenuItem,
	MenuList,
	Paper,
	Popper,
	Typography,
} from '@mui/material';

export default function MenuButton({ label, link, subCategories, ...props }) {
	const dispatch = useDispatch();
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [open, setOpen] = React.useState(false);
	const [clicked, setClicked] = React.useState(false);

	const handleClick = () => {
		if (link && !props.onClickDisabled) {
			handleRoute({ label, link });
		} else {
			setOpen(!open);
			setClicked(!clicked);
		}
	};

	const handleClose = () => {
		setAnchorEl(null);
		setClicked(null);
	};

	const handleRoute = (subCategory) => {
		handleClose(false);
		dispatch(goToRoute(subCategory.link));
	};

	function handleListKeyDown(event) {
		if (event.key === 'Tab') {
			event.preventDefault();
			handleClose();
		}
	}

	const handleHoverButton = (e) => {
		setAnchorEl(e.currentTarget);
		setOpen(true);
	};

	const handleHoverOut = () => {
		setOpen(false);
	};

	const renderMenuItem = (subCategory, key) => {
		let menuItemProps = {};

		if (subCategory.link) {
			menuItemProps.onClick = () => handleRoute(subCategory);
		} else if (subCategory.goTo) {
			menuItemProps.component = 'a';
			menuItemProps.href = subCategory.goTo;
		}

		return (
			<MenuItem {...menuItemProps} key={key}>
				{subCategory.label}
			</MenuItem>
		);
	};

	const popperRenderPropFunction = ({ TransitionProps }) => (
		<Grow {...TransitionProps}>
			<Paper>
				<ClickAwayListener onClickAway={handleClose}>
					<MenuList
						autoFocusItem={Boolean(anchorEl)}
						id="menu-list-grow"
						onKeyDown={handleListKeyDown}
					>
						{subCategories.map(renderMenuItem)}
					</MenuList>
				</ClickAwayListener>
			</Paper>
		</Grow>
	);

	return (
		<Box onMouseOver={handleHoverButton} onMouseOut={handleHoverOut}>
			<Button
				color="secondary"
				aria-controls="simple-menu"
				aria-haspopup="true"
				onClick={handleClick}
				className={props.buttonClass}
			>
				<Typography variant="body1">{label}</Typography>
			</Button>
			{anchorEl && (
				<Popper
					open={open || clicked || false}
					anchorEl={anchorEl}
					transition
					disablePortal
				>
					{popperRenderPropFunction}
				</Popper>
			)}
		</Box>
	);
}

MenuButton.propTypes = {
	subCategories: PropTypes.array.isRequired,
	label: PropTypes.string.isRequired,
	buttonClass: PropTypes.string,
	analyticsProps: PropTypes.object,
	link: PropTypes.string,
	onClickDisabled: PropTypes.bool,
};

MenuButton.defaultProps = {
	buttonClass: 'defaultButtonLabel',
	analyticsProps: {},
	onClickDisabled: false,
	link: '',
};

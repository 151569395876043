import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
	menuItemBottomBorder: {
		borderBottom: '4px solid ' + theme.palette.primary.main,
		borderRadius: '0',
	},
	defaultButtonLabel: {
		textTransform: 'none',
		marginLeft: theme.spacing(1.2),
		height: '100%',
		fontSize: '0.5rem',
		[theme.breakpoints.down('md')]: {
			marginLeft: theme.spacing(0),
		},
	},
	invertedButtonLabel: {
		color: theme.palette.primary.main,
		textTransform: 'none',
		marginLeft: theme.spacing(2),
		height: '100%',
		fontSize: '1rem',
		[theme.breakpoints.down('md')]: {
			marginLeft: theme.spacing(0),
		},
	},
	outlinedButtonLabel: {
		color: theme.palette.primary.main,
		border: '1px solid ' + theme.palette.primary.main,
		textTransform: 'none',
		fontSize: '1rem',
		marginLeft: theme.spacing(2),
		[theme.breakpoints.down('md')]: {
			marginLeft: theme.spacing(0),
		},
	},
	tag: {
		fontSize: '0.65rem',
		borderRadius: '5px',
		[theme.breakpoints.down('md')]: {
			fontSize: '0.55rem',
		},
		height: theme.spacing(2.5),
		padding: theme.spacing(0, 0.25),
	},
}));

export default useStyles;

const defaultConfig = require('./default');

module.exports = {
	...defaultConfig,
	envName: 'DEVELOPMENT',
	google_tag_manager_args: {
		gtmId: 'GTM-MB9PGHD',
		auth: '5UiF21ol5cogn0IYNyvgaQ',
		preview: 'env-112',
	},
	SENTRY_DSN: '',
	awsConfig: {
		awsKey: 'AKIA4KCCKZYLKVL63MFF',
		bucket: 'azent-documents',
		region: 'ap-south-1',
		s3BucketBaseUrl: 'https://azent-documents.s3.ap-south-1.amazonaws.com',
	},
	fileApi: 'https://staging-api.azent.com/',
	fileUploadURL: '/aws-request-sign-awssigningrequest-LDH4GFWVPV2T',
	seo: {
		...defaultConfig.seo,
		ampBaseUrl: 'https://amp.azent.com',
	},
	cookie: {
		domain: '',
		path: '/',
		maxAge: 2629746,
		authAccessToken: 'azentUserData.development',
		referralCodeCookie: 'AzentReferralCode.development',
		formData: 'azentformData.development',
		redirect: 'azentUrlData.development',
		kioskCentre: 'azentKioskData.development',
	},
	cmsApi: 'http://localhost:1337/v1',
	cmsApiV1: 'https://staging-cms-service.azent.com',
	authApi: 'https://staging-api.azent.com',
	dataApi: 'https://staging-api.azent.com/v1',
	eventsApi: 'http://staging-events-api.azent.com/v1',
	lmsApi: 'https://staging-lms-service.azent.com/v1',
	studentServiceApi: 'https://staging-student-api.azent.com/v1',
	studentPlatformUrl: 'http://localhost:3000/',
	businessPlatformUrl: 'https://staging-partners.admissionbox.com',
	ieltsWebsiteUrl: 'https://testprep.azent.com',
};

import Link from 'next/link';
import PropTypes from 'prop-types';

// compoenent
import ImageComponent from '@/components/widgets/image';
import LoginProfileButton from '@/components/widgets/loginProfileButton';
import MenuActionButton from '@/components/widgets/menuActionButton';

// material
import { AppBar, Box, LinearProgress, Toolbar } from '@mui/material';

// style
import useStyles from './style';

export default function DesktopMenuBar({ logo, actions, loading }) {
	const { classes } = useStyles();
	return (
		<Box>
			<AppBar className={classes.appBar}>
				<Toolbar className={classes.toolbar}>
					<Box>
						<Link href="/" prefetch={false}>
							<a>
								<ImageComponent
									src={logo.url}
									title={logo.title}
									height={logo.height}
									width={logo.width}
									priority={true}
									loading="eager"
									alt={logo.alternativeText}
								/>
							</a>
						</Link>
					</Box>
					<Box className={classes.menuActionSection}>
						{actions.map((action, index) => (
							<Box mr={1} key={index}>
								<MenuActionButton {...action} />
							</Box>
						))}
						<Box ml={2}>
							<LoginProfileButton buttonType="menuOption" />
						</Box>
					</Box>
				</Toolbar>
				{loading && <LinearProgress color="primary" />}
			</AppBar>
		</Box>
	);
}

DesktopMenuBar.propTypes = {
	logo: PropTypes.object.isRequired,
	actions: PropTypes.array,
	loading: PropTypes.bool,
};

DesktopMenuBar.defaultProps = {
	actions: [],
	loading: false,
};

// Constants
import constants from '@/constants';
import { getPersistentData } from '@/utils/browserStorage/persistentData';
import axios from 'axios';
import { handleCommonRequest, handleCommonResponse } from './common-response';

let getHeaders = function () {
	let headers = {};
	let authAccessToken = getPersistentData(constants.cookie.authAccessToken);
	if (authAccessToken) {
		headers['Authorization'] = 'Bearer ' + authAccessToken;
	}
	return headers;
};

let instance = axios.create({
	baseURL: constants.authApi + '/v1',
	headers: getHeaders(),
});

instance.interceptors.request.use(
	function (config) {
		config.headers = getHeaders();
		return config;
	},
	function (error) {
		console.log(error);
		return Promise.reject(error);
	}
);

handleCommonRequest(instance, false);
handleCommonResponse(instance);

export default instance;

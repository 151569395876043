import { createTheme } from '@mui/material/styles';

const theme = createTheme({
	breakpoints: {
		values: {
			xs: 300,
			sm: 600,
			md: 900,
			lg: 1200,
			xl: 1536,
		},
	},
	components: {
		MuiOutlinedInput: {
			styleOverrides: {
				root: {
					padding: '8px 8px',
				},
				input: {
					padding: '4px 4px',
				},
			},
		},
		MuiInputLabel: {
			styleOverrides: {
				root: {
					color: '#797979',
				},
			},
		},
		MuiCssBaseline: {
			styleOverrides: {
				body: {
					fontFamily: '"Montserrat", "Helvetica", "Arial", "sans-serif"',
				},
				a: {
					textDecoration: 'none',
					color: '#232323',
				},
				'a-webkit-any-link': {
					textDecoration: 'none',
					color: '#232323',
				},
			},
		},
		MuiButton: {
			styleOverrides: {
				root: {
					textTransform: 'capitalize',
				},
				containedSuccess: {
					color: '#fff',
				},
			},
		},
	},
	palette: {
		primary: {
			main: '#F16621',
		},
		secondary: {
			main: '#263238',
		},
		success: {
			main: '#00C853',
		},
		text: {
			primary: '#232323',
			secondary: '#ffffff',
			disabled: '#6d6e71',
			hint: '#f8f8f8',
			lightGrey: '#cccccc',
			grey: '#797979',
		},
		common: {
			black: '#000000',
			lightBlack: '#3A3A3A',
			white: '#ffffff',
			grey: '#D9D9D9',
			lightGrey: '#F8F9FA',
			blue: '#2196f3',
			lightOrange: '#FFF0E3',
			backgroundGrey: '#f3f3f3',
			textLightGrey: '#6B6B6B',
			lightBlue: '#F4F9FC',
			greyishBlue: '#f6f8fc',
			linkBlue: '#0645AD',
			facebookBlue: '#4267B2',
			whatsappGreen: '#25D366',
		},
	},
	typography: {
		fontFamily: '"Montserrat", "Helvetica", "Arial", "sans-serif"',
		fontStretch: 'normal',
		fontStyle: 'normal',
		fontWeightRegular: 400,
		fontWeightMedium: 500,
		fontWeightSemiBold: 600,
		fontWeightBold: 700,
		h1: {
			fontFamily: '"Montserrat", "Helvetica", "Arial", "sans-serif"',
			fontWeight: 700,
			fontSize: '60px',
			lineHeight: 1.21,
		},
		h2: {
			fontFamily: '"Montserrat", "Helvetica", "Arial", "sans-serif"',
			fontWeight: 600,
			fontSize: '48px',
			lineHeight: 1.22,
		},
		h3: {
			fontFamily: '"Montserrat", "Helvetica", "Arial", "sans-serif"',
			fontWeight: 500,
			fontSize: '40px',
			lineHeight: 1.21,
		},
		h4: {
			fontFamily: '"Montserrat", "Helvetica", "Arial", "sans-serif"',
			fontWeight: 600,
			fontSize: '32px',
			lineHeight: 1.21,
		},
		h5: {
			fontFamily: '"Montserrat", "Helvetica", "Arial", "sans-serif"',
			fontWeight: 600,
			fontSize: '24px',
			lineHeight: 1.21,
		},
		h6: {
			fontFamily: '"Montserrat", "Helvetica", "Arial", "sans-serif"',
			fontWeight: 600,
			fontSize: '20px',
			lineHeight: 1.21,
		},
		subtitle1: {
			fontFamily: '"Montserrat", "Helvetica", "Arial", "sans-serif"',
			fontWeight: 500,
			fontSize: '16px',
			lineHeight: 1.25,
		},
		subtitle2: {
			fontFamily: '"Montserrat", "Helvetica", "Arial", "sans-serif"',
			fontWeight: 400,
			fontSize: '14px',
			lineHeight: 1.2,
		},
		body1: {
			fontFamily: '"Montserrat", "Helvetica", "Arial", "sans-serif"',
			fontWeight: 400,
			fontSize: '16px',
			lineHeight: 1.5,
		},
		body2: {
			fontFamily: '"Montserrat", "Helvetica", "Arial", "sans-serif"',
			fontWeight: 400,
			fontSize: '16px',
			lineHeight: 1.25,
		},

		button: {
			fontFamily: '"Montserrat", "Helvetica", "Arial", "sans-serif"',
			fontWeight: 600,
			fontSize: '20px',
			lineHeight: 1.75,
		},
		caption: {
			fontFamily: '"Montserrat", "Helvetica", "Arial", "sans-serif"',
			fontWeight: 400,
			fontSize: '12px',
			lineHeight: 1.25,
		},
		overline: {
			fontFamily: '"Montserrat", "Helvetica", "Arial", "sans-serif"',
			fontWeight: 400,
			fontSize: '12px',
			lineHeight: 2.66,
		},
	},
});

export default theme;

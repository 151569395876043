import { imageSizeTypes } from '@/components/shared/imageGallerySection/constants';

export const configOptions = {
	[imageSizeTypes.large]: {
		height: '75%',
		desktop: '155px',
		largeTablet: '130px',
		tablet: '155px',
		mobile: '130px',
		gap: {
			desktop: '48px',
			largeTablet: '24px',
			tablet: '48px',
			mobile: '24px',
		},
	},
	[imageSizeTypes.medium]: {
		height: '75%',
		desktop: '124px',
		largeTablet: '124px',
		tablet: '124px',
		mobile: '124px',
		gap: {
			desktop: '24px',
			largeTablet: '24px',
			tablet: '24px',
			mobile: '24px',
		},
	},
	[imageSizeTypes.small]: {
		height: '75%',
		desktop: '88px',
		largeTablet: '88px',
		tablet: '88px',
		mobile: '88px',
		gap: {
			desktop: '24px',
			largeTablet: '24px 16px',
			tablet: '24px',
			mobile: '24px 8px',
		},
	},
};

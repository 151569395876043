const mobileDetect = (userAgent) => {
	const isAndroid = () => Boolean(userAgent.match(/Android/i));
	const isIos = () => Boolean(userAgent.match(/iPhone|iPad|iPod/i));
	const isOpera = () => Boolean(userAgent.match(/Opera Mini/i));
	const isWindows = () => Boolean(userAgent.match(/IEMobile/i));
	const isGooglebot = () => Boolean(userAgent.match(/Googlebot/i));

	const isMobile = () =>
		Boolean(isAndroid() || isIos() || isOpera() || isWindows());

	return {
		isMobile,
		isGooglebot,
	};
};

export default mobileDetect;

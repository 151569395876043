import constants from '@/constants';
import {
	removePersistentData,
	setPersistentData,
} from '@/utils/browserStorage/persistentData';
import { getStudentInfoFromToken } from '@/utils/tokenParser';
import authInstance from './axios-instances/auth';
const defaultErrorMessage = 'Some error occurred, Please try again.';

export async function sendOTP(data) {
	let result = {};
	await authInstance
		.post('/auth/login/request_otp', data)
		.then((res) => {
			if (res.data.success) result.success = res.data.success;
		})
		.catch((error) => {
			result.success = false;
			result.errorMessage = defaultErrorMessage;
			if (
				error.response &&
				error.response.data &&
				error.response.data.error &&
				error.response.data.error.message
			) {
				result.errorMessage = error.response.data.error.message;
			}
		});

	return result;
}

export const logIn = async (loginDetails) => {
	let result = {};
	await authInstance
		.post('/auth/login/otp', { ...loginDetails })
		.then((res) => {
			if (res.data.data.access_token && res.data.success) {
				result.success = true;
				let accessToken = res.data.data.access_token;
				result.userInfo = getStudentInfoFromToken(accessToken);
				result.userData = res.data.data.user;
				setPersistentData(constants.cookie.authAccessToken, accessToken, {
					path: constants.cookie.path,
					domain: constants.cookie.domain,
					maxAge: constants.cookie.maxAge,
					sameSite: 'none',
					secure: true,
				});
			}
		})
		.catch((error) => {
			result.success = false;
			result.errorMessage = defaultErrorMessage;
			if (
				error.response &&
				error.response.data &&
				error.response.data.error &&
				error.response.data.error.message
			) {
				result.errorMessage = error.response.data.error.message;
			}
		});
	return result;
};

export const auth = async () => {
	let result = {};

	await authInstance
		.get('/auth')
		.then((res) => {
			if (res.data.success && res.data.data.user) {
				result = {
					success: true,
					data: {
						userType: 'student',
						...res.data.data.user,
						registered: true,
					},
				};
			}
		})
		.catch((error) => {
			result.success = false;
			result.errorMessage = defaultErrorMessage;
			if (
				error.response &&
				error.response.data &&
				error.response.data.error &&
				error.response.data.error.message
			) {
				result.errorMessage = error.response.data.error.message;
			}

			removePersistentData(constants.cookie.authAccessToken, {
				path: constants.cookie.path,
				domain: constants.cookie.domain,
			});
		});
	return result;
};

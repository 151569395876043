import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
	appBar: {
		backgroundColor: theme.palette.common.white,
		position: 'fixed',
	},
	toolbar: {
		paddingTop: theme.spacing(1),
		height: theme.spacing(10),
	},
	menuActionSection: {
		display: 'flex',
		justifyContent: 'flex-end',
		alignItems: 'center',
		width: '100%',
		margin: theme.spacing(0, 2),
	},
	loginButton: {
		borderRadius: '70px',
		fontSize: theme.typography.body1.fontSize,
	},
}));

export default useStyles;

import { logIn, sendOTP } from '@/api/auth';
import constants from '@/constants';
import { resetStudentData } from '@/store/reducers/profile';
import { removePersistentData } from '@/utils/browserStorage/persistentData';
import { resetUserData, setUserRegistered } from '..';

export const sendLoginOTP = (data) => async () => {
	return await sendOTP(data);
};

export const loginUser = (loginDetails) => async (dispatch) => {
	const result = await logIn(loginDetails);
	if (result.success) dispatch(setUserRegistered(true));
	return result;
};

export const logoutUser = () => {
	return (dispatch) => {
		removePersistentData(constants.cookie.authAccessToken, {
			path: constants.cookie.path,
			domain: constants.cookie.domain,
		});
		dispatch(resetUserData());
		dispatch(resetStudentData());
	};
};

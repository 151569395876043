import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme, { showBackgroundImage }) => ({
	bodyContainer: {
		paddingTop: theme.spacing(10),
		background: showBackgroundImage
			? 'url(https://production-azent-website-cms-uploads.s3.ap-south-1.amazonaws.com/blog_page_bg_23508a3b04.svg)'
			: 'unset',
		backgroundSize: '100%',
		backgroundRepeat: 'no-repeat',
		[theme.breakpoints.down('sm')]: {
			paddingTop: theme.spacing(10),
			background: 'unset',
		},
	},
	footerContainer: {
		backgroundColor: theme.palette.common.lightGrey,
	},
}));

export default useStyles;

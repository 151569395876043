import get from 'lodash/get';
import Link from 'next/link';
import PropTypes from 'prop-types';

// Components
import ImageComponent from '@/components/widgets/image';

// Material
import {
	Facebook,
	Instagram,
	Language,
	Link as LinkIcon,
	LinkedIn,
	Twitter,
	YouTube,
} from '@mui/icons-material';
import { Box } from '@mui/material';

// actions
import { isExternalUrl } from '@/store/actions';

// Styles
import useStyles from './style';

const materialIconElementMap = {
	facebook: Facebook,
	youtube: YouTube,
	twitter: Twitter,
	instagram: Instagram,
	linkedin: LinkedIn,
	website: Language,
	other: LinkIcon,
};
export default function IconAction({
	icon,
	materialIcon,
	goto,
	label,
	link,
	...props
}) {
	const { classes } = useStyles(props);

	const Icon = materialIconElementMap[materialIcon];

	const ImageElement = materialIcon ? (
		<Box className={classes.materialIconWrap}>
			<Icon color="primary" />
		</Box>
	) : (
		<Box className={classes.iconWrap}>
			<ImageComponent
				objectFit="contain"
				src={get(icon, 'data.attributes.url', '')}
				layout="fill"
				title={label}
				alt={get(icon, 'data.attributes.alternativeText', '')}
			/>
		</Box>
	);

	return (
		<Box>
			{goto || isExternalUrl(link) ? (
				<a
					href={goto || link}
					className={classes.goTo}
					target="_blank"
					rel="noopener noreferrer"
				>
					{ImageElement}
				</a>
			) : link ? (
				<Link href={link} prefetch={false}>
					<a>{ImageElement}</a>
				</Link>
			) : (
				ImageElement
			)}
		</Box>
	);
}

IconAction.propTypes = {
	icon: PropTypes.object,
	label: PropTypes.string,
	goto: PropTypes.string,
	link: PropTypes.string,
	iconWidth: PropTypes.string,
	iconHeight: PropTypes.string,
	materialIcon: PropTypes.string,
};

IconAction.defaultProps = {
	label: '',
	goto: '',
	link: '#',
	iconWidth: '35px',
	iconHeight: '35px',
};

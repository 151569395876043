const { pageTypes } = require('../utils/constants/pageTypes');
const defaultConfig = require('./default');

module.exports = {
	...defaultConfig,
	google_tag_manager_args: {
		gtmId: 'GTM-5PNZHKK',
		auth: '-UtEK9il8HhxHbwarfmMGg',
		preview: 'env-1',
	},
	SENTRY_DSN:
		'https://31b81a0d93d6444cbccfe106e5142bdc@o409291.ingest.sentry.io/4504099341205504',
	awsConfig: {
		awsKey: 'AKIA4KCCKZYLKVL63MFF',
		bucket: 'azent-documents',
		region: 'ap-south-1',
		s3BucketBaseUrl: 'https://azent-documents.s3.ap-south-1.amazonaws.com',
	},
	fileApi: 'https://api.azent.com/',
	fileUploadURL: '/aws-request-sign-awssigningrequest-13W23EBGNMGJV',
	seo: {
		...defaultConfig.seo,
		sitemapBaseUrl: 'https://admissionbox.com',
		policies: [{ userAgent: '*', allow: '/' }],
		index: true,
		noIndexPages: new Set([
			pageTypes.country,
			pageTypes.blog,
			pageTypes.courseFinderUniversity,
			pageTypes.courseFinderUniversityDetail,
			pageTypes.courseFinderUniversityPrograms,
		]),
		pathExcludedFromSiteMap: [
			...defaultConfig.seo.pathExcludedFromSiteMap,
			'/expense-calculator',
			'/IELTS-events',
			'/study-abroad-events',
			'/university-visits',
			'/web-stories',
			'/events-webinars*',
			'/course-finder*',
			'/expert-tips*',
			'/scholarships*',
		],
	},
	cookie: {
		domain: '.azent.com',
		path: '/',
		maxAge: 2629746,
		authAccessToken: 'azentUserData.partnerProduction',
		referralCodeCookie: 'AzentReferralCode.partnerProduction',
		formData: 'azentformData.partnerProduction',
		redirect: 'azentUrlData.partnerProduction',
		kioskCentre: 'azentKioskData.partnerProduction',
	},
	cmsApi: ' https://partner-cms-service.azent.com/v1',
	cmsApiV1: 'https://cms-service.azent.com',
	authApi: 'https://api.azent.com',
	dataApi: 'https://api.azent.com/v1',
	eventsApi: 'https://events-api.azent.com/v1',
	lmsApi: 'https://lms-api.azent.com/v1',
	studentServiceApi: 'https://student-api.azent.com/v1',
	studentPlatformUrl: 'https://compass.azent.com/',
	businessPlatformUrl: 'https://partners.admissionbox.com',
	ieltsWebsiteUrl: 'https://testprep.azent.com',
	useStructuredData: false,
};

import qs from 'qs';
import studentInstance from './axios-instances/student';

export function getJourneyData(queryConfig = {}) {
	return studentInstance.get(
		`journeys/widgets/timelines?${qs.stringify(queryConfig, {
			encodeValuesOnly: true,
		})}`
	);
}

export const getProfile = async () => {
	let result;

	await studentInstance
		.get(`/profiles/me`)
		.then((res) => (result = res.data))
		.catch(() => {});

	return result || null;
};

import get from 'lodash/get';
import { useSelector } from 'react-redux';

// utils
import matchPath from '@/utils/url/matchPath';

export default function useFloatingButtons() {
	const pathname = useSelector((state) => state.router.location.pathname);
	const { scrollButtonPagePaths = [], chatbotIgnorePagePaths = [] } =
		useSelector((state) =>
			get(state.cmsGlobalData, 'data.globalFloatingButtonConfig', {})
		);

	const [showFloatingButton, setShowFloatingButton] = React.useState({
		scroll: matchPath(pathname, scrollButtonPagePaths),
		chatbot: !matchPath(pathname, chatbotIgnorePagePaths),
	});

	const computeShowFloatingButtons = () => {
		if (typeof window === 'undefined') return;

		setShowFloatingButton({
			scroll: matchPath(pathname, scrollButtonPagePaths),
			chatbot: !matchPath(pathname, chatbotIgnorePagePaths),
		});
	};

	return [showFloatingButton, computeShowFloatingButtons];
}

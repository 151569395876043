// Constants
import constants from '@/constants';
// Utils
import { getPersistentData } from '@/utils/browserStorage/persistentData';

const getHeaders = () => {
	let headers = {};
	let authTokenValue = getPersistentData(constants.cookie.authAccessToken);
	if (authTokenValue) {
		headers.Authorization = 'Bearer ' + authTokenValue;
	}
	return headers;
};

export default getHeaders;

import axios from 'axios';

// Constants
import constants from '@/constants';
import { handleCommonRequest, handleCommonResponse } from './common-response';

let instance = axios.create({
	baseURL: constants.cmsApiV1,
	headers: {},
});

handleCommonRequest(instance, false);
handleCommonResponse(instance);

export default instance;

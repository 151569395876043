import { programKeywords } from '@/utils/constants/program';
import { deserializeInterestRegions } from '@/utils/deserializer/universityPage';
import qs from 'qs';
import universityInstance from './axios-instances/university';

export function fetchGlobalData() {
	return universityInstance.get(`/data`);
}
export function getStatePlaces(query) {
	let search = new URLSearchParams(query);
	let url = '/data/places/state?';
	if (search) {
		url += search.toString() + '&';
	}
	url += 'countries=IN';
	return universityInstance.get(url);
}

export function getCountryPlaces(query) {
	let search = new URLSearchParams(query);
	return universityInstance.get(`/data/places/country?${search.toString()}`);
}

export function getCityPlaces(query) {
	let search = new URLSearchParams(query);
	let url = '/data/places/city?';
	if (search) {
		url += search.toString() + '&';
	}
	url += 'countries=IN';
	return universityInstance.get(url);
}

export function getStateFromCity(cityName) {
	return universityInstance.get(
		`/data/state-from-city/${encodeURIComponent(cityName)}`
	);
}

export function getUniversityData(universitySlug, queryConfig = {}) {
	const query = qs.stringify(queryConfig, {
		encodeValuesOnly: true,
	});
	return universityInstance
		.get(`/universities/slugs/${encodeURIComponent(universitySlug)}?${query}`)
		.catch((err) => (err.response.status === 404 ? null : Promise.reject(err)));
}

export function getProgrammeData(programID, query = {}) {
	let search = new URLSearchParams(query);
	return universityInstance.get(`/programs/${programID}?${search.toString()}`);
}

export function universitySearch(query) {
	let search = new URLSearchParams(query);
	return universityInstance.get(`/universities/search?${search.toString()}`);
}

export function getUniversities(queryConfig = {}) {
	const query = qs.stringify(queryConfig, {
		encodeValuesOnly: true,
	});
	return universityInstance.get(`/universities?${query}`);
}

export function getUniversitySlugs(query) {
	let search = new URLSearchParams(query);
	return universityInstance.get(`/universities/slugs?${search.toString()}`);
}

export const getScholarships = (queryConfig = {}) => {
	const query = qs.stringify(queryConfig, {
		encodeValuesOnly: true,
	});
	return universityInstance.get(`/scholarship?${query}`);
};

export function getScholarship(scholarshipSlug, queryConfig = {}) {
	const query = qs.stringify(queryConfig, {
		encodeValuesOnly: true,
	});
	return universityInstance
		.get(`/scholarship/slugs/${encodeURIComponent(scholarshipSlug)}?${query}`)
		.catch((err) => (err.response.status === 404 ? null : Promise.reject(err)));
}

export function getPrograms(query) {
	let search = new URLSearchParams(query);
	return universityInstance.get(`/programs?${search.toString()}`);
}

export function getProgramsOfUniversity(university_id, query) {
	let search = new URLSearchParams(query);
	return universityInstance.get(
		`/programs/university/${university_id}?${search.toString()}`
	);
}

export function subCategorySearch(query) {
	let queryCat = query.categories || [];
	let categories = query.allCategories;
	return new Promise((resolve) => {
		setTimeout(() => {
			resolve({
				data: queryCat
					.reduce((acc, item) => {
						return acc.concat(
							categories[item].map((i) => {
								return { name: i };
							})
						);
					}, [])
					.sort((a, b) => a.name.localeCompare(b.name)),
			});
		}, 0);
	});
}

export function keywordSearch(payload) {
	return universityInstance
		.post(`/programs/autocomplete`, payload)
		.then((response) => {
			const data =
				response.data.length === 0 && !payload.text
					? programKeywords
					: response.data;
			return { data: data.map((keyword) => ({ keyword })) };
		});
}

export function regionSearch(config) {
	let search = new URLSearchParams(config);
	return universityInstance
		.get(`/data/regions?${search.toString()}&regions=true`)
		.then((response) => ({ data: deserializeInterestRegions(response.data) }))
		.catch(() => ({ data: [] }));
}

import get from 'lodash/get';
import { useDispatch, useSelector } from 'react-redux';

// Actions
import {
	goToExternalRoute,
	goToRoute,
	isExternalUrl,
	sendOTPAndOpenUserModal,
	setUserModalData,
} from '@/store/actions';

// Utils
import { getOTPPayload } from '@/utils/auth';
import getPlatform from '@/utils/getPlatform';
import redirectToPlatform from '@/utils/redirect-to-platform';

const isPartnerPlatform = getPlatform() === 'partner';

export default function useUserModal() {
	const dispatch = useDispatch();
	const user = useSelector((state) => state.user.data);
	const location = useSelector((state) => state.router.location);
	const userModal = useSelector((state) => state.userModal.data);
	const cmsGlobalData = useSelector((state) => state.cmsGlobalData.data);

	/**
	 * Control the user modal
	 * @param {string | false} [modalType] type of modal to open
	 * @param {string} [phoneNumber] phone number
	 * @param {object} [config] optional configurations
	 * @param {boolean} [config.sendOTPToNumber] - send OTP to provided number
	 * @param {boolean} [config.preventRedirect] - prevent redirection to platform
	 * @param {string} [config.redirectionLink] - link to redirect to after otp verification
	 */
	const controlUserModal = (modalType = 'login', phoneNumber = '', config) => {
		const {
			sendOTPToNumber = false,
			preventRedirect = false,
			redirectionLink = '',
		} = config || {};

		const _phoneNumber = (
			userModal.phoneNumber ||
			phoneNumber ||
			''
		).toString();

		if (!preventRedirect && (isPartnerPlatform || (user && user.id))) {
			if (redirectionLink) {
				if (isExternalUrl(redirectionLink))
					goToExternalRoute(redirectionLink, null, {}, null, true);
				else dispatch(goToRoute(redirectionLink));

				return;
			}

			redirectToPlatform(user);
		} else if (sendOTPToNumber && phoneNumber) {
			dispatch(
				sendOTPAndOpenUserModal(
					{ modalType, phoneNumber: _phoneNumber, redirectionLink },
					getOTPPayload(
						_phoneNumber,
						false,
						get(cmsGlobalData, 'globalFormConfig.authConstants'),
						location
					)
				)
			);
		} else {
			dispatch(
				setUserModalData({
					modalType,
					phoneNumber: _phoneNumber,
					otpSent: userModal.otpSent || false,
					redirectionLink,
				})
			);
		}
	};

	return controlUserModal;
}

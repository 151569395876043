const defaultConfig = require('./default');
module.exports = {
	...defaultConfig,
	google_tag_manager_args: {
		gtmId: 'GTM-MB9PGHD',
		auth: '5UiF21ol5cogn0IYNyvgaQ',
		preview: 'env-112',
	},
	// DISABLE SENTRY FOR STAGING
	// SENTRY_DSN:
	//     'https://44c9e7fc4dcb44beb9e108a0ea6f6388@o409291.ingest.sentry.io/5497407',
	SENTRY_DSN: '',
	awsConfig: {
		awsKey: 'AKIA4KCCKZYLKVL63MFF',
		bucket: 'azent-documents',
		region: 'ap-south-1',
		s3BucketBaseUrl: 'https://azent-documents.s3.ap-south-1.amazonaws.com',
	},
	fileApi: 'https://staging-api.azent.com/',
	fileUploadURL: '/aws-request-sign-awssigningrequest-LDH4GFWVPV2T',
	seo: {
		...defaultConfig.seo,
		sitemapBaseUrl: 'https://staging-website-v2.azent.com',
		ampBaseUrl: 'https://amp.azent.com',
	},
	cookie: {
		domain: '.azent.com',
		path: '/',
		maxAge: 2629746,
		authAccessToken: 'azentUserData.staging',
		referralCodeCookie: 'AzentReferralCode.staging',
		formData: 'azentformData.staging',
		redirect: 'azentUrlData.staging',
		kioskCentre: 'azentKioskData.staging',
	},
	cmsApi: 'https://staging-cms-service-v2.azent.com/v1',
	cmsApiV1: 'https://staging-cms-service.azent.com',
	authApi: 'https://staging-api.azent.com',
	dataApi: 'https://staging-universities-api.azent.com/v1',
	eventsApi: 'http://staging-events-api.azent.com/v1',
	lmsApi: 'https://staging-lms-service.azent.com/v1',
	studentServiceApi: 'https://staging-student-api.azent.com/v1',
	studentPlatformUrl: 'https://staging-student.azent.com/',
	businessPlatformUrl: 'https://staging-partners.admissionbox.com',
	ieltsWebsiteUrl: 'https://testprep.azent.com',
};

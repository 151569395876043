// Default
import Image from 'next/image';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

export default function ImageComponent({ className, ...props }) {
	const isMobile = useSelector((state) => state.common.isMobile);
	let imageProps = { quality: isMobile ? '60%' : '75%', ...props };

	if (imageProps.layout === 'fill') {
		imageProps.objectFit = imageProps.objectFit || 'cover';
		imageProps.objectPosition = imageProps.objectPosition || 'center';
	} else {
		imageProps.width = imageProps.width || '100%';
		imageProps.height = imageProps.height || '100%';
	}

	return (
		<Image
			src={imageProps.src}
			alt={imageProps.alt}
			className={className}
			placeholder="blur"
			blurDataURL="/placeholder.svg"
			{...imageProps}
		/>
	);
}

ImageComponent.propTypes = {
	src: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	alt: PropTypes.string,
	layout: PropTypes.string,
	objectFit: PropTypes.string,
	objectPosition: PropTypes.string,
	className: PropTypes.string,
	unoptimized: PropTypes.bool,
	loading: PropTypes.string,
	quality: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	onLoadingComplete: PropTypes.func,
};

ImageComponent.defaultProps = {
	priority: false,
};

import get from 'lodash/get';
// API
import { getCMSData } from '@/api';
import { createSlice } from '@reduxjs/toolkit';

const prepareMenuBar = (data) => ({
	...data,
	logo: {
		title: data.logo.title,
		url: get(data, 'logo.logo.data.attributes.url', ''),
		alternativeText: get(
			data,
			'logo.logo.data.attributes.alternativeText',
			data.logo.title
		),
	},
	actions:
		(data.actions &&
			data.actions.map((action) => {
				const subActions =
					(action.subActions &&
						action.subActions.data &&
						action.subActions.data.map((subAction) => subAction.attributes)) ||
					[];
				return { ...action, subActions };
			})) ||
		[],
});

const initialState = {
	loading: false,
	data: null,
	kiosk: null,
	error: null,
};

export const menuBar = createSlice({
	name: 'menuBar',
	initialState,
	reducers: {
		getMenuBarProps(state) {
			state.loading = true;
		},
		getMenuBarPropsSuccess: {
			reducer: (state, { payload }) => {
				state.loading = false;
				state.data = payload.mainMenu;
				state.kiosk = payload.kioskMenu;
			},
			prepare: (mainMenu, kioskMenu) => ({
				payload: {
					mainMenu: prepareMenuBar(mainMenu),
					kioskMenu: prepareMenuBar(kioskMenu),
				},
			}),
		},
		getMenuBarPropsFailure(state, { payload }) {
			state.loading = false;
			state.error = payload;
		},
	},
});

const { getMenuBarProps, getMenuBarPropsSuccess, getMenuBarPropsFailure } =
	menuBar.actions;

function getMenuBarPropsAction() {
	return (dispatch) => {
		dispatch(getMenuBarProps());
		return Promise.all([
			getCMSData('menu-bar', {
				populate: 'deep',
			}),
			getCMSData('kiosk-menu-bar', {
				populate: 'deep',
			}),
		])
			.then((response) => {
				dispatch(
					getMenuBarPropsSuccess(
						get(response, '[0].data.data.attributes'),
						get(response, '[1].data.data.attributes')
					)
				);
			})
			.catch((err) => {
				dispatch(getMenuBarPropsFailure(err));
			});
	};
}

export default menuBar;
export { getMenuBarPropsAction };

function getPlatform() {
	switch (process.env.APP_ENV) {
		case 'partnerProduction':
		case 'partnerStaging': {
			return 'partner';
		}

		case 'ieltsProduction':
		case 'ieltsStaging': {
			return 'ielts';
		}

		case 'production':
		case 'development':
		case 'staging': {
			return 'website';
		}
	}
}
module.exports = getPlatform;

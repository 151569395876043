import Link from 'next/link';
import PropTypes from 'prop-types';

// material
import { Drawer, List, ListSubheader } from '@mui/material';

// component
import LoginProfileButton from '@/components/widgets/loginProfileButton';
import MobileMenuActionButton from '@/components/widgets/mobileMenuActionButton';

// style
import useStyles from './style';

export default function MobileSideNav({
	drawerOpen,
	setDrawerOpen,
	actions,
	analyticsProps,
}) {
	const { classes } = useStyles();
	return (
		<Drawer
			anchor="left"
			open={drawerOpen}
			onClose={() => setDrawerOpen(false)}
		>
			<List
				subheader={
					<Link href="/" prefetch={false}>
						<a>
							<ListSubheader color="primary">
								Azent Overseas Education
							</ListSubheader>
						</a>
					</Link>
				}
				className={classes.menuList}
			>
				{actions.map((action, index) => (
					<MobileMenuActionButton
						{...action}
						setDrawerOpen={setDrawerOpen}
						analyticsProps={analyticsProps}
						key={index}
					/>
				))}

				<LoginProfileButton
					buttonType="listOption"
					analyticsProps={analyticsProps}
				/>
			</List>
		</Drawer>
	);
}

MobileSideNav.propTypes = {
	drawerOpen: PropTypes.bool,
	setDrawerOpen: PropTypes.func,
	actions: PropTypes.array.isRequired,
	analyticsProps: PropTypes.object,
};
MobileSideNav.defaultProps = {
	drawerOpen: false,
	actions: [],
	analyticsProps: {},
};

// Constants
import constants from '@/constants';
// Utils
import {
	getCookie,
	removeCookie,
	setCookie,
} from '@/utils/browserStorage/cookie';
import { checkLocalStorageEnabled } from '@/utils/browserStorage/local-storage';

export function checkPersistentData(key, cookieOptions = {}) {
	let value = null;
	if (checkLocalStorageEnabled() && key !== constants.cookie.authAccessToken) {
		value = window.localStorage.getItem(key);
	}
	if (!value) {
		value = getCookie(key, cookieOptions);
	}
	return Boolean(value);
}

export function setPersistentData(key, value, cookieOptions = {}) {
	if (checkLocalStorageEnabled() && key !== constants.cookie.authAccessToken) {
		window.localStorage.setItem(key, value);
	}

	setCookie(key, value, cookieOptions);
}

export function removePersistentData(key, cookieOptions = {}) {
	if (checkLocalStorageEnabled() && key !== constants.cookie.authAccessToken) {
		window.localStorage.removeItem(key);
	}

	removeCookie(key, cookieOptions);
}

export function getPersistentData(key, cookieOptions = {}) {
	let value = null;

	if (checkLocalStorageEnabled() && key === constants.cookie.formData) {
		value = window.localStorage.getItem(key);
		if (value && value !== 'null') return JSON.parse(value);
	}

	if (checkLocalStorageEnabled() && key !== constants.cookie.authAccessToken) {
		value = window.localStorage.getItem(key);
		if (value && value !== 'null') return value;
	}

	value = getCookie(key, cookieOptions);

	return value;
}

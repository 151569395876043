import get from 'lodash/get';
import PropTypes from 'prop-types';

// Components
import IconAction from '@/components/shared/iconAction';
import GenericButton from '@/components/widgets/genericButton';

// Material
import { Box, Typography } from '@mui/material';

// Styles
import useStyles from './style';

export default function DesktopOnlySection({
	socialIconActions,
	androidAppIcon,
	iosAppIcon,
	iconButton,
	...props
}) {
	const { classes } = useStyles();

	return (
		<>
			<Box mb={3}>
				<Typography className={classes.logoDescription}>
					{props.logoDescription}
				</Typography>
			</Box>

			{iconButton && (
				<GenericButton
					variant="contained"
					color="success"
					className={classes.iconButton}
					label={iconButton.label}
					icon={{
						url: get(iconButton, 'icon.data.attributes.url', ''),
						alt:
							get(iconButton, 'icon.data.attributes.alternativeText') ||
							iconButton.label ||
							'',
					}}
					link={iconButton.link}
				/>
			)}

			<Box className={classes.socialInfo}>
				{socialIconActions && socialIconActions.length > 0 && (
					<Box className={classes.socialIconWrap}>
						{socialIconActions.map((iconAction, index) => (
							<IconAction key={index} {...iconAction} />
						))}
					</Box>
				)}
				{props.footerAppIconLabel && (
					<Box mb={2}>
						<Typography className={classes.logoDescription}>
							{props.footerAppIconLabel}
						</Typography>
					</Box>
				)}
				{(androidAppIcon || iosAppIcon) && (
					<Box className={classes.appLinkWrap}>
						{androidAppIcon && (
							<IconAction
								iconWidth="115px"
								iconHeight="45px"
								{...androidAppIcon}
							/>
						)}
						{iosAppIcon && (
							<IconAction iconWidth="115px" iconHeight="45px" {...iosAppIcon} />
						)}
					</Box>
				)}
			</Box>
		</>
	);
}

DesktopOnlySection.propTypes = {
	logoDescription: PropTypes.string,
	socialIconActions: PropTypes.array,
	footerAppIconLabel: PropTypes.string,
	androidAppIcon: PropTypes.object,
	iosAppIcon: PropTypes.object,
	iconButton: PropTypes.object,
};

DesktopOnlySection.defaultProps = {
	logoDescription: '',
	socialIconActions: [],
	footerAppIconLabel: '',
};

import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(
	(_, { iconHeight, iconWidth, circularIcon }) => ({
		iconWrap: {
			width: iconWidth || '35px',
			height: iconHeight || '35px',
			position: 'relative',
			borderRadius: circularIcon ? '50%' : 'unset',
			overflow: 'hidden',
		},
		materialIconWrap: {
			width: iconWidth || '35px',
			height: iconHeight || '35px',
			borderRadius: circularIcon ? '50%' : 'unset',
			backgroundColor: '#FFEDDA',
			overflow: 'hidden',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
		},
	})
);

export default useStyles;

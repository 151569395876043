import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
	logoDescription: {
		textAlign: 'left',
		margin: theme.spacing(0, 'auto'),
	},
	socialInfo: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start',
		[theme.breakpoints.down('md')]: {
			alignItems: 'center',
		},
		[theme.breakpoints.down('sm')]: {
			padding: theme.spacing(0, 4),
		},
	},
	socialIconWrap: {
		display: 'flex',
		gap: theme.spacing(2),
		marginBottom: theme.spacing(2),
	},
	appLinkWrap: {
		display: 'flex',
		flexDirection: 'row',
		gap: theme.spacing(2),
		[theme.breakpoints.down('sm')]: {
			alignItems: 'center',
		},
	},
	desktopOnly: {
		[theme.breakpoints.down('md')]: {
			display: 'none',
		},
	},
	iconButton: {
		borderRadius: '22px',
		padding: '8px 30px',
		fontSize: '16px',
		marginBottom: theme.spacing(5),
	},
}));

export default useStyles;

import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
	defaultButtonLabel: {
		textTransform: 'none',
		marginLeft: theme.spacing(3),
	},
	invertedButtonLabel: {
		color: theme.palette.primary.main,
		textTransform: 'none',
		marginLeft: theme.spacing(3),
	},
	outlinedButtonLabel: {
		color: theme.palette.primary.main,
		border: '1px solid ' + theme.palette.primary.main,
		textTransform: 'none',
		marginLeft: theme.spacing(3),
	},
	chipLabel: {
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		maxWidth: '70px',
	},
	iconButton: {
		padding: theme.spacing(0),
		width: 'fit-content',
	},
	avatar: {
		backgroundColor: theme.palette.secondary.main,
		color: theme.palette.common.white,
		width: theme.spacing(3),
		height: theme.spacing(3),
		fontSize: theme.typography.subtitle2.fontSize,
		paddingLeft: theme.spacing(0),
		fontWeight: theme.typography.fontWeightBold,
	},
}));
export default useStyles;

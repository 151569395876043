import Link from 'next/link';
import PropTypes from 'prop-types';
import React from 'react';

// material
import { List, ListItem, ListItemText, ListSubheader } from '@mui/material';

// styles
import useStyles from './style';

export default function FooterAction({ action }) {
	const { classes } = useStyles();

	const renderListItem = (subOption, key) => {
		let listItemProps = {
			button: true,
			className: classes.subActionLabel,
		};

		if (subOption.link) {
			listItemProps.component = 'div';
		} else if (subOption.goTo) {
			listItemProps.component = 'a';
			listItemProps.href = subOption.goTo;
		}

		const ListItemElement = (
			<ListItem {...listItemProps}>
				<ListItemText primary={subOption.label} />
			</ListItem>
		);

		return subOption.link ? (
			<Link href={subOption.link} key={key} prefetch={false}>
				<a>{ListItemElement}</a>
			</Link>
		) : (
			<React.Fragment key={key}>{ListItemElement}</React.Fragment>
		);
	};

	return (
		<List
			disablePadding={true}
			subheader={
				<ListSubheader component="div" className={classes.footerActionTitle}>
					{action.label}
				</ListSubheader>
			}
		>
			{action.subActions && action.subActions.map(renderListItem)}
		</List>
	);
}

FooterAction.propTypes = {
	action: PropTypes.object,
};
FooterAction.defaultProps = {
	action: {},
};

import Link from 'next/link';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

// component
import MenuButton from './partials/menuButton';

// material
import { Box, Button, Typography } from '@mui/material';

// utils
import { isExternalUrl } from '@/store/actions';

// style
import useStyles from './style';

export default function MenuAction({ label, link, subActions, ...props }) {
	const location = useSelector((state) => state.router.location);
	const { classes } = useStyles();

	let extraClassName = '';
	if (link && !isExternalUrl(link)) {
		if (location.pathname.split('/')[1] === link.split('/')[1]) {
			extraClassName = classes.menuItemBottomBorder;
		} else if (
			location.pathname.split('/')[1].split('?')[0] === link.split('/')[1]
		) {
			extraClassName = classes.menuItemBottomBorder;
		}
	}

	let buttonClassName = classes[props.buttonClass];
	if (extraClassName) buttonClassName = `${buttonClassName} ${extraClassName}`;

	let ActionButtonElement = (
		<Button color="secondary" className={buttonClassName}>
			<Typography variant="body1">{label}</Typography>
		</Button>
	);

	if (link) {
		ActionButtonElement = (
			<Link href={link} prefetch={false}>
				<a>{ActionButtonElement}</a>
			</Link>
		);
	}

	if (props.type == 'expand' && subActions.length > 0) {
		ActionButtonElement = (
			<MenuButton
				subCategories={subActions}
				label={label}
				buttonClass={buttonClassName}
				analyticsProps={props.analyticsProps}
				onClickDisabled={props.onClickDisabled}
				link={link}
			/>
		);
	}

	return <Box>{ActionButtonElement}</Box>;
}

MenuAction.propTypes = {
	label: PropTypes.string.isRequired,
	link: PropTypes.string,
	subActions: PropTypes.array,
	type: PropTypes.string,
	buttonClass: PropTypes.string,
	onClickDisabled: PropTypes.bool,
	analyticsProps: PropTypes.object,
};

MenuAction.defaultProps = {
	analyticsProps: {},
	buttonClass: 'defaultButtonLabel',
	subActions: [],
};

import constants from '@/constants';
import isEmpty from 'lodash/isEmpty';
import getPlatform from './getPlatform';

const isPlatformPartner = getPlatform() === 'partner';

export default function redirectToPlatform(user, delayInMs) {
	const platformUrl = isPlatformPartner
		? constants.businessPlatformUrl
		: constants.studentPlatformUrl;

	if (!isPlatformPartner && (!user || !user.id)) return;

	if (delayInMs) {
		setTimeout(() => {
			window.location.href = platformUrl;
		}, 2000);
	} else {
		window.location.href = platformUrl;
	}

	// const isStudent = user.roles.some(({ name }) =>
	// 	['STUDENT', 'LEAD'].includes(name)
	// );
}

export const getCompassCourseFinderURL = (filters, otherParams) => {
	const search = new URLSearchParams();

	if (!isEmpty(filters)) {
		search.set('filters_keys', JSON.stringify(filters));
	}

	if (!isEmpty(otherParams)) {
		for (const key in otherParams) {
			search.set(key, otherParams[key]);
		}
	}

	return `${constants.studentPlatformUrl}course-finder?${search.toString()}`;
};

import get from 'lodash/get';
import isPlainObject from 'lodash/isPlainObject';

// API
import { getCMSData } from '@/api';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	loading: false,
	data: null,
	error: null,
};

const config = {
	globalFormConfig: {
		getValidData: (data) => ({
			schemaConstants:
				get(data, 'schemaConstants') && isPlainObject(data.schemaConstants)
					? data.schemaConstants
					: {},
			authConstants:
				get(data, 'authConstants') && isPlainObject(data.authConstants)
					? data.authConstants
					: {},
		}),
	},
	globalBlogConfig: {
		getValidData: (data = {}) => ({
			countryFilterOptions:
				get(data, 'countryFilterOptions') &&
				Array.isArray(data.countryFilterOptions)
					? data.countryFilterOptions
					: [],
		}),
	},
	globalFloatingButtonConfig: {
		getValidData: (data = {}) => ({
			chatbotIgnorePagePaths:
				get(data, 'chatbotIgnorePagePaths') &&
				Array.isArray(data.chatbotIgnorePagePaths)
					? data.chatbotIgnorePagePaths
					: [],
			scrollButtonPagePaths:
				get(data, 'scrollButtonPagePaths') &&
				Array.isArray(data.scrollButtonPagePaths)
					? data.scrollButtonPagePaths
					: [],
			loginWidgetPagePaths:
				get(data, 'loginWidgetPagePaths') &&
				Array.isArray(data.loginWidgetPagePaths)
					? data.loginWidgetPagePaths
					: [],
		}),
	},
	globalAnnouncementConfig: {
		getValidData: (data = {}) => ({
			ignorePagePaths:
				get(data, 'ignorePagePaths') && Array.isArray(data.ignorePagePaths)
					? data.ignorePagePaths
					: [],
			config:
				get(data, 'config') && Array.isArray(data.config) ? data.config : [],
		}),
	},
};

export const cmsGlobalData = createSlice({
	name: 'cmsGlobalData',
	initialState,
	reducers: {
		getCMSGlobalProps(state) {
			state.loading = true;
		},
		getCMSGlobalPropsSuccess(state, { payload }) {
			state.loading = false;
			let contentSlug;
			state.data = payload.reduce((content, data) => {
				contentSlug = data.attributes.contentSlug;
				if (get(data, 'attributes.contentData[0]') && config[contentSlug])
					content[contentSlug] = config[contentSlug].getValidData(
						data.attributes.contentData[0]
					);

				return content;
			}, {});
		},
		getCMSGlobalPropsFailure(state, { payload }) {
			state.loading = false;
			state.error = payload;
		},
	},
});

const {
	getCMSGlobalProps,
	getCMSGlobalPropsSuccess,
	getCMSGlobalPropsFailure,
} = cmsGlobalData.actions;

function getCMSGlobalData() {
	return (dispatch) => {
		dispatch(getCMSGlobalProps());

		return getCMSData('global-contents', { populate: 'deep' })
			.then((response) => {
				let data = response && response.data && response.data.data;
				dispatch(getCMSGlobalPropsSuccess(data || []));
			})
			.catch((err) => {
				dispatch(getCMSGlobalPropsFailure(err));
			});
	};
}

export default cmsGlobalData;
export { getCMSGlobalData };

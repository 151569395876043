export const imageSizeTypes = {
	small: 'small',
	medium: 'medium',
	large: 'large',
};
export const imageShapeTypes = {
	circle: 'circle',
	rectangle: 'rectangle',
};

export const config = {
	[imageSizeTypes.large]: {
		perItemWidth: {
			desktop: '50%',
			tablet: '100%',
			mobile: '100%',
		},
		[imageShapeTypes.circle]: {
			width: '400px',
		},
		[imageShapeTypes.rectangle]: {
			height: '62.5%',
		},
	},
	[imageSizeTypes.medium]: {
		perItemWidth: {
			desktop: '25%',
			tablet: '50%',
			mobile: '50%',
		},
		[imageShapeTypes.circle]: {
			width: '200px',
		},
		[imageShapeTypes.rectangle]: {
			height: '62.5%',
		},
	},
	[imageSizeTypes.small]: {
		perItemWidth: {
			desktop: '16%',
			tablet: '33%',
			mobile: '50%',
		},
		[imageShapeTypes.circle]: {
			width: '120px',
		},
		[imageShapeTypes.rectangle]: {
			height: '62.5%',
		},
	},
};

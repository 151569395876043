import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
	button: {
		borderRadius: '33px',
		display: 'inline-block',
	},
	iconWrap: {
		width: '24px',
		height: '24px',
		position: 'relative',
		overflow: 'hidden',
		marginRight: '8px',
	},
}));

export default useStyles;

// material
import { Box } from '@mui/material';

// styles
import useStyles from './style';

export default function Spinner() {
	const { classes } = useStyles();
	return <Box className={classes.spinnerContainer} />;
}

const development = require('./development');
const staging = require('./staging');
const production = require('./production');
var ieltsProduction = require('./ieltsProduction');
var partnerProduction = require('./partnerProduction');

let envs = {
	development,
	staging,
	production,
	ieltsProduction,
	partnerProduction,
};

module.exports = envs[process.env.APP_ENV];

// API
import { getGlobalData } from '@/api';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	loading: false,
	data: null,
	error: null,
};

export const global = createSlice({
	name: 'global',
	initialState,
	reducers: {
		getGlobalProps(state) {
			state.loading = true;
		},
		getGlobalPropsSuccess(state, { payload }) {
			state.loading = false;
			state.data = payload;
		},
		getGlobalPropsFailure(state, { payload }) {
			state.loading = false;
			state.error = payload;
		},
	},
});

const { getGlobalProps, getGlobalPropsSuccess, getGlobalPropsFailure } =
	global.actions;

function getGlobalPropsAction() {
	return (dispatch) => {
		dispatch(getGlobalProps());
		return getGlobalData('menu-bar')
			.then((response) => {
				dispatch(getGlobalPropsSuccess(response.data));
			})
			.catch((err) => {
				dispatch(getGlobalPropsFailure(err));
			});
	};
}

export default global;
export { getGlobalPropsAction };

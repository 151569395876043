import { goBack, push } from 'connected-next-router';
import { reverse } from 'named-urls';

let goToRoute = function (path, params = {}, query = null) {
	return (dispatch) => {
		let routePath = reverse(path, params);
		if (query) {
			let search = new URLSearchParams(query);
			routePath = `${routePath}?${search.toString()}`;
		}
		dispatch(push(routePath));
	};
};

let setQuery = function (key, value) {
	return (dispatch, getState) => {
		let state = getState();
		let search = state.router.location.search;
		let routePath = state.router.location.pathname;
		let searchParams = new URLSearchParams(search);
		searchParams.set(key, value);
		routePath = `${routePath}?${searchParams.toString()}`;
		dispatch(push(routePath));
	};
};
let setQueries = function (arr) {
	return (dispatch, getState) => {
		let state = getState();
		let routePath = state.router.location.pathname;
		let searchParams = new URLSearchParams();
		arr.forEach((i) => {
			searchParams.set(i.key, i.value);
		});
		routePath = `${routePath}?${searchParams.toString()}`;
		dispatch(push(routePath));
	};
};

let goToExternalRoute = function (
	baseURL,
	path,
	params = {},
	query = null,
	newTab = true
) {
	let routePath = path ? reverse(path, params) : reverse(params);
	if (query) {
		let search = new URLSearchParams(query);
		routePath = `${routePath}?${search.toString()}`;
	}
	let fullUrl = baseURL + routePath;

	if (newTab) {
		const windowRef = window.open(fullUrl, '_blank');
		if (windowRef) return windowRef;
		else window.location.href = fullUrl;
	} else {
		window.location.href = fullUrl;
	}
};

let isExternalUrl = function (url) {
	const pattern = /^((http|https):\/\/)/;
	return pattern.test(url);
};

const setQueriesWithOptions = function (
	arr,
	scrollToTop = false,
	refreshPage = false,
	otherOptions = {}
) {
	return (dispatch, getState) => {
		let state = getState();
		let routePath = state.router.location.pathname;
		let searchParams = new URLSearchParams();
		if (otherOptions.allowMultipleSameKey) {
			arr.forEach(({ key = '', value = '' }) =>
				value.forEach((v) => searchParams.append(key, v))
			);
		} else {
			arr.forEach((i) => searchParams.set(i.key, i.value));
		}
		routePath = `${routePath}?${searchParams.toString()}`;

		let options = { shallow: !refreshPage, scroll: scrollToTop };
		dispatch(push(routePath, undefined, options));
	};
};

let goToPreviousRoute = function () {
	return (dispatch) => {
		dispatch(goBack());
	};
};

export {
	goToRoute,
	setQuery,
	setQueries,
	goToExternalRoute,
	isExternalUrl,
	setQueriesWithOptions,
	goToPreviousRoute,
};

export default function parseJwt(token) {
    if (!token) {
        return;
    }
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
}

export function getStudentInfoFromToken(token) {
    let infoFromToken = parseJwt(token);
    let studentInfo = {
        accessToken: token,
        id: infoFromToken.sub,
        ...infoFromToken.payload,
    };
    return studentInfo;
}

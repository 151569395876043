import { sendOTP } from '@/api/auth';
import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
	loading: false,
	data: {
		modalType: null,
		otpSent: false,
		phoneNumber: '',
		redirectionLink: '',
		postSuccessAnalyticsProps: null,
		remainingTime: 30,
	},
	error: null,
};
let intervalId;

export const userModal = createSlice({
	name: 'userModal',
	initialState,
	reducers: {
		setUserModalLoading: (state, { payload }) => {
			state.loading = payload;
		},
		setUserModalError: (state, { payload }) => {
			state.error = payload;
		},
		setUserModalData: (state, { payload }) => {
			for (const key in state.data) {
				if (key in payload) state.data[key] = payload[key];
			}
		},
		resetUserModalData: () => {
			clearInterval(intervalId);
			return initialState;
		},
	},
});

const { setUserModalLoading, setUserModalError, setUserModalData } =
	userModal.actions;

export function sendOTPAndOpenUserModal(modalData, otpData) {
	return async (dispatch) => {
		dispatch(setUserModalLoading(true));
		dispatch(setUserModalData(modalData));
		const result = await sendOTP(otpData);

		if (!result.success) dispatch(setUserModalError(result.errorMessage));

		dispatch(setUserModalLoading(false));
		dispatch(setUserModalData({ ...modalData, otpSent: result.success }));
		if (result.success) {
			dispatch(setResendOTPTime(30));
		}
		return result;
	};
}

export const setResendOTPTime = (remainingTime) => (dispatch) => {
	if (intervalId) clearInterval(intervalId);
	if (remainingTime === 0) return;

	let time = remainingTime;
	dispatch(setUserModalData({ remainingTime: time }));
	intervalId = setInterval(() => {
		dispatch(setUserModalData({ remainingTime: --time }));
		if (time === 0) clearInterval(intervalId);
	}, 1000);
};

export default userModal;

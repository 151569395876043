// Constants
import constants from '@/constants';
// Utils
import getHeaders from '@/utils/get-headers';
import axios from 'axios';
import { handleCommonRequest, handleCommonResponse } from './common-response';

let instance = axios.create({
	baseURL: constants.lmsApi,
	headers: getHeaders(),
});

handleCommonRequest(instance, true);
handleCommonResponse(instance);

export default instance;

import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(
	(theme, { widgetPosition = 0, buttonColor = 'primary' }) => {
		let backgroundColor = theme.palette[buttonColor]
			? theme.palette[buttonColor].main
			: buttonColor;

		const commonStyles = {
			position: 'fixed',
			backgroundColor,
			color: theme.palette.common.white,
			textTransform: 'capitalize',
			'&:hover': { backgroundColor },
		};

		return {
			scrollButton: {
				...commonStyles,
				bottom: theme.spacing(widgetPosition),
				right: theme.spacing(2),
				zIndex: '100',
				transition:
					'transform 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, bottom 100ms linear!important',
				[theme.breakpoints.down('xs')]: {
					zIndex: '100',
				},
			},
			button: {
				...commonStyles,
				bottom: theme.spacing(0),
				width: '100%',
				borderRadius: '0px',
				height: '64px',
			},
			buttonText: {
				fontSize: '18px',
				fontWeight: theme.typography.fontWeightSemiBold,
			},
		};
	}
);

export default useStyles;

import qs from 'qs';
import cmsInstance from './axios-instances/cms';
import cmsInstanceV1 from './axios-instances/cms-v1';

export const getCMSData = (component, queryConfig = {}, cmsVersion = 'v2') => {
	let query = qs.stringify(queryConfig, {
		encodeValuesOnly: true,
	});
	const instance = cmsVersion === 'v1' ? cmsInstanceV1 : cmsInstance;
	return instance.get(`/${component}?${query}`);
};

export const postCMSData = (component, data) => {
	return cmsInstance.post(`/${component}`, data);
};

export async function getAllForCollection(component, queryConfig = {}) {
	let buffer = 100,
		interval,
		collectionData = [],
		callWaitList = [],
		query = qs.stringify(
			{
				pagination: { start: 0, limit: buffer },
				sort: ['publishedAt:desc'],
				...queryConfig,
			},
			{ encodeValuesOnly: true }
		);

	const result = await cmsInstance.get(`/${component}?${query}`);
	collectionData.push(...result.data.data);

	const count = result.data.meta.pagination.total - buffer;
	if (count < 1) return collectionData;

	if (count % buffer > 0) interval = Math.floor(count / buffer) + 1;
	else interval = Math.floor(count / buffer);

	interval = Array.from(Array(interval).keys()).map((i) => ({
		pagination: { start: buffer * (i + 1), limit: buffer },
		sort: ['publishedAt:desc'],
		...queryConfig,
	}));

	interval.forEach((data) => {
		query = qs.stringify(data, { encodeValuesOnly: true });

		callWaitList.push(
			cmsInstance.get(`/${component}?${query}`).then((result) => {
				collectionData.push(...result.data.data);
			})
		);
	});

	if (callWaitList.length) await Promise.all(callWaitList);
	return collectionData;
}

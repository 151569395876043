import qs from 'qs';
import lmsInstance from './axios-instances/lms';

export const recordVC = (queryConfig = {}) => {
	let query = qs.stringify(queryConfig, {
		encodeValuesOnly: true,
	});

	return lmsInstance.get(`/record/vc?${query}`).catch(() => null);
};

export function applyCoupon(data) {
	return lmsInstance.post(`/coupons/apply`, data);
}

export function createUser(data) {
	return lmsInstance.post(`/record`, data);
}

export function createMeeting(data) {
	return lmsInstance.post(`/meetings`, data);
}

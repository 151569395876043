import Link from 'next/link';
import PropTypes from 'prop-types';

// component
import MobileSideNav from '@/components/shared/menuBar/partials/mobileSideNav';
import ImageComponent from '@/components/widgets/image';
import LoginProfileButton from '@/components/widgets/loginProfileButton';

// material
import { Menu } from '@mui/icons-material';
import {
	AppBar,
	Box,
	IconButton,
	LinearProgress,
	Toolbar,
} from '@mui/material';

// style
import useStyles from './style';

export default function MobileMenuBar({ logo, ...props }) {
	const [drawerOpen, setDrawerOpen] = React.useState(false);
	const [drawerOpenedOnce, setDrawerOpenedOnce] = React.useState(false);

	React.useEffect(() => {
		if (drawerOpen) {
			setDrawerOpenedOnce(true);
		}
	}, [drawerOpen]);

	const { classes } = useStyles();

	let logoClicked = () => {};

	let menuIconClicked = () => {
		setDrawerOpen(true);
	};

	return (
		<Box>
			<AppBar className={classes.appBar}>
				<Toolbar className={classes.toolbar}>
					<Box position="relative">
						<Box>
							<IconButton onClick={menuIconClicked}>
								<Menu color="primary" />
							</IconButton>
						</Box>
						<Box className={classes.middleLineOverlay}></Box>
					</Box>
					<Box flexGrow={1} className={classes.menuBarLeft}>
						<Link href="/" prefetch={false}>
							<a>
								<ImageComponent
									src={logo.url}
									title={logo.title}
									priority={true}
									onClick={logoClicked}
									height={logo.height}
									width={logo.width}
									loading="eager"
									alt={logo.alternativeText}
								/>
							</a>
						</Link>
					</Box>
					<Box ml={3}>
						<LoginProfileButton buttonType="menuOption" />
					</Box>

					{drawerOpenedOnce && (
						<MobileSideNav
							drawerOpen={drawerOpen}
							setDrawerOpen={setDrawerOpen}
							actions={props.actions}
							analyticsProps={props.analyticsProps}
						/>
					)}
				</Toolbar>
				{props.loading && <LinearProgress color="primary" />}
			</AppBar>
		</Box>
	);
}

MobileMenuBar.propTypes = {
	logo: PropTypes.object.isRequired,
	actions: PropTypes.array.isRequired,
	analyticsProps: PropTypes.object,
	loading: PropTypes.bool.isRequired,
	contactDetails: PropTypes.object.isRequired,
};
MobileMenuBar.defaultProps = {
	analyticsProps: {},
};

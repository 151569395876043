import { createSlice } from '@reduxjs/toolkit';

export const filterInitialState = {
	tag: {
		categories: [],
		countries: [],
		tags: [],
	},
	formFilter: {},
	inputText: '',
	toggleValue: '',
};

const filter = createSlice({
	name: 'filter',
	initialState: filterInitialState,
	reducers: {
		setFilterData: (state, { payload }) => {
			for (const key in state) {
				if (key in payload) state[key] = payload[key];
			}
		},
		setFormFilterData: (state, { payload }) => {
			state.formFilter = payload;
		},
		setFilterText: (state, { payload }) => {
			state.inputText = payload;
		},
		setFilterTag: (state, { payload }) => {
			for (const key in state.tag) {
				if (key in payload) state.tag[key] = payload[key];
			}
		},
		setFilterToggle: (state, { payload }) => {
			state.toggleValue = payload;
		},
		resetFilterData: (state) => {
			Object.assign(state, filterInitialState);
		},
	},
});

export default filter;

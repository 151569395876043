import Link from 'next/link';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

// material
import { Box, Divider, ListItem, ListItemText } from '@mui/material';

// component
import ExpandingListItem from './partials/expandingListItem';

// utils
import { isExternalUrl } from '@/store/actions';

// style
import useStyles from './style';

export default function MobileMenuActionButton({
	label,
	link,
	subActions,
	...props
}) {
	const { classes } = useStyles();
	const location = useSelector((state) => state.router.location);

	let extraClassName = '';
	if (link && !isExternalUrl(link)) {
		if (location.pathname.split('/')[1] === link.split('/')[1]) {
			extraClassName = classes.menuItemBottomBorder;
		} else if (
			location.pathname.split('/')[1].split('?')[0] === link.split('/')[1]
		) {
			extraClassName = classes.menuItemBottomBorder;
		}
	}

	let buttonClassName = classes[props.buttonClass];
	if (extraClassName) buttonClassName = `${buttonClassName} ${extraClassName}`;

	let handleActionClick = () => {
		props.setDrawerOpen(false);
	};

	if (props.type == 'expand' && subActions.length > 0) {
		return (
			<>
				<ExpandingListItem
					label={label}
					open={false}
					subOptions={subActions}
					className={buttonClassName}
					analyticsProps={props.analyticsProps}
					setDrawerOpen={props.setDrawerOpen}
				/>
				<Divider />
			</>
		);
	}

	let ActionButtonElement = (
		<ListItem
			button
			className={buttonClassName}
			onClick={() => handleActionClick()}
		>
			<ListItemText primary={label} />
		</ListItem>
	);

	if (link) {
		ActionButtonElement = (
			<Link href={link} prefetch={false}>
				<a>{ActionButtonElement}</a>
			</Link>
		);
	}

	return (
		<Box display="flex">
			{ActionButtonElement}
			<Divider />
		</Box>
	);
}

MobileMenuActionButton.propTypes = {
	label: PropTypes.string.isRequired,
	link: PropTypes.string,
	subActions: PropTypes.array,
	type: PropTypes.string,
	buttonClass: PropTypes.string,
	setDrawerOpen: PropTypes.func,
	analyticsProps: PropTypes.object,
};
MobileMenuActionButton.defaultProps = {
	analyticsProps: {},
	buttonClass: 'defaultButtonLabel',
	subActions: [],
};

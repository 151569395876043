const pageTypes = {
	page: '',
	city: 'city',
	blog: 'expert-tips',
	event: 'events-webinars',
	universityEvents: 'university-visits',
	country: 'country',
	landing: 'page',
	thankYou: 'thank-you',
	program: 'program',
	courseFinderUniversityDetail: 'course-finder/university-detail',
	courseFinderUniversityPrograms: 'course-finder/university-programs',
	courseFinderUniversity: 'course-finder/universities',
	product: 'product',
	sitemap: 'sitemap',
	studyAbroadEvents: 'study-abroad-events',
	ieltsEvents: 'IELTS-events',
	expenseCalculator: 'expense-calculator',
	courseFinder: 'course-finder',
	scholarships: 'scholarships',
	scholarshipDetail: 'scholarship-detail',
	webStories: 'web-stories',
	j: 'j',
	kioskCenter: 'kiosk/center',
	kioskUniversityFeedback: 'kiosk/university-feedback',
	kioskBlogList: 'kiosk/expert-tips-list',
	counsellorsList: 'counsellors-list',
	counsellor: 'counsellor',
	recordTestimonial: 'record-testimonial',
};

module.exports = { pageTypes };

import { withStyles } from 'tss-react/mui';

const styleWrapper = (Component) =>
	withStyles(Component, (theme) => ({
		errorContainer: {
			height: '60vh',
			width: '100%',
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			alignItems: 'center',
		},
		title: {
			textAlign: 'center',
			...theme.typography.h2,
			[theme.breakpoints.down('sm')]: {
				...theme.typography.h5,
				fontWeight: theme.typography.fontWeightSemiBold,
			},
		},
	}));

export default styleWrapper;

import Link from 'next/link';
import PropTypes from 'prop-types';
import React from 'react';

// material
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Collapse, List, ListItem, ListItemText } from '@mui/material';

// style
import useStyles from './style';

function ExpandingListItem(props) {
	const { classes } = useStyles();
	let [listOpen, setListOpen] = React.useState(props.open);

	let mainLabelClicked = () => {
		setListOpen(!listOpen);
	};
	let subOptionClicked = () => {
		props.setDrawerOpen(false);
	};

	const renderListItem = (subOption, key) => {
		let listItemProps = {
			button: true,
			className: classes.nested,
			onClick: () => subOptionClicked(subOption),
		};

		if (subOption.link) {
			listItemProps.component = 'div';
		} else if (subOption.goTo) {
			listItemProps.component = 'a';
			listItemProps.href = subOption.goTo;
		}

		const ListItemElement = (
			<ListItem {...listItemProps}>
				<ListItemText primary={subOption.label} />
			</ListItem>
		);

		return subOption.link ? (
			<Link href={subOption.link} key={key}>
				<a>{ListItemElement}</a>
			</Link>
		) : (
			<React.Fragment key={key}>{ListItemElement}</React.Fragment>
		);
	};

	return (
		<>
			<ListItem button onClick={mainLabelClicked} className={props.className}>
				<ListItemText primary={props.label} />
				{listOpen ? <ExpandLess /> : <ExpandMore />}
			</ListItem>
			<Collapse in={listOpen} timeout="auto" unmountOnExit>
				<List component="div" disablePadding>
					{props.subOptions.map(renderListItem)}
				</List>
			</Collapse>
		</>
	);
}

export default React.memo(ExpandingListItem);

ExpandingListItem.propTypes = {
	open: PropTypes.bool,
	subOptions: PropTypes.array.isRequired,
	label: PropTypes.string.isRequired,
	analyticsProps: PropTypes.object,
	setDrawerOpen: PropTypes.func.isRequired,
	className: PropTypes.string,
};

ExpandingListItem.defaultProps = {
	open: false,
	analyticsProps: {},
	className: '',
};

import Cookies from 'universal-cookie';

const cookies = new Cookies();

function checkCookieEnabled() {
	if (
		typeof window !== 'undefined' &&
		window.document &&
		navigator &&
		navigator.cookieEnabled
	) {
		return true;
	} else {
		return false;
	}
}

function getCookie(key, cookieOptions = {}) {
	let value = null;
	if (checkCookieEnabled()) {
		value = cookies.get(key, cookieOptions);
	}
	return value;
}

function setCookie(key, value, cookieOptions = {}) {
	if (checkCookieEnabled()) {
		return cookies.set(key, value, cookieOptions);
	}
}

function removeCookie(key, cookieOptions = {}) {
	if (checkCookieEnabled()) {
		return cookies.remove(key, cookieOptions);
	}
}

export default cookies;
export { checkCookieEnabled, getCookie, removeCookie, setCookie };

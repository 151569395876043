const defaultConfig = require('./default');
module.exports = {
	...defaultConfig,
	google_tag_manager_args: {
		gtmId: 'GTM-NQ48JD8',
		auth: 'Exys8ei4DtsX46TPUMTk8w',
		preview: 'env-1',
	},
	SENTRY_DSN:
		'https://ed87644b8b6549b286289f8e3defa83b@o409291.ingest.sentry.io/4504099347038208',
	awsConfig: {
		awsKey: 'AKIA4KCCKZYLKVL63MFF',
		bucket: 'azent-documents',
		region: 'ap-south-1',
		s3BucketBaseUrl: 'https://azent-documents.s3.ap-south-1.amazonaws.com',
	},
	fileApi: 'https://api.azent.com/',
	fileUploadURL: '/aws-request-sign-awssigningrequest-13W23EBGNMGJV',
	seo: {
		...defaultConfig.seo,
		sitemapBaseUrl: 'https://testprep.azent.com',
		policies: [{ userAgent: '*', allow: '/' }],
		pathExcludedFromSiteMap: [
			...defaultConfig.seo.pathExcludedFromSiteMap,
			'/expense-calculator',
			'/IELTS-events',
			'/study-abroad-events',
			'/university-visits',
			'/web-stories',
			'/events-webinars*',
			'/course-finder*',
			'/expert-tips*',
			'/scholarships*',
		],
		index: true,
	},
	cookie: {
		domain: '.azent.com',
		path: '/',
		maxAge: 2629746,
		authAccessToken: 'azentUserData.production',
		referralCodeCookie: 'AzentReferralCode.production',
		formData: 'azentformData.production',
		redirect: 'azentUrlData.production',
		kioskCentre: 'azentKioskData.production',
	},
	cmsApi: 'https://ielts-cms-service.azent.com/v1',
	cmsApiV1: 'https://cms-service.azent.com',
	authApi: 'https://api.azent.com',
	dataApi: 'https://api.azent.com/v1',
	eventsApi: 'https://events-api.azent.com/v1',
	lmsApi: 'https://lms-api.azent.com/v1',
	studentServiceApi: 'https://student-api.azent.com/v1',
	studentPlatformUrl: 'https://compass.azent.com/',
	businessPlatformUrl: 'https://partners.admissionbox.com',
	ieltsWebsiteUrl: 'https://testprep.azent.com',
};

// Actions
import { setDevice } from '@/store/actions';
// Material
import useMediaQuery from '@mui/material/useMediaQuery';
// Default
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useStyles } from 'tss-react/mui';

export default function DeviceSetter() {
	const { theme } = useStyles();
	const dispatch = useDispatch();

	const isMobile = useSelector((state) => state.common.isMobile);
	const isDesktop = useSelector((state) => state.common.isDesktop);
	const isTablet = useSelector((state) => state.common.isTablet);
	const isCssMobile = useMediaQuery(theme.breakpoints.down('sm'), {
		noSsr: true,
	});
	const isCssDesktop = useMediaQuery(theme.breakpoints.up('md'), {
		noSsr: true,
	});
	const isCssTablet = useMediaQuery(
		theme.breakpoints.up('sm') && theme.breakpoints.down('md'),
		{
			noSsr: true,
		}
	);

	React.useEffect(() => {
		if (
			isCssMobile !== isMobile ||
			isCssDesktop !== isDesktop ||
			isCssTablet !== isTablet
		) {
			dispatch(
				setDevice({
					isMobile: isCssMobile,
					isDesktop: isCssDesktop,
					isTablet: isCssTablet,
				})
			);
		}
	}, [isCssMobile, isCssDesktop, isDesktop, isMobile, isCssTablet, isTablet]);

	return <></>;
}

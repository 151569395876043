async function loadIntersectionObserverPolyfill() {
	const supportsIntersectionObserver =
		'IntersectionObserver' in window &&
		'IntersectionObserverEntry' in window &&
		'intersectionRatio' in window.IntersectionObserverEntry.prototype;

	if (supportsIntersectionObserver) {
		await import('intersection-observer');
	}
}

if (typeof window !== 'undefined') {
	loadIntersectionObserverPolyfill();
}

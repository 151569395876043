import { Box, Typography } from '@mui/material';
import styleWrapper from './style';

class ErrorBoundary extends React.Component {
	constructor() {
		super();
		this.state = { hasError: false };
	}

	static getDerivedStateFromError() {
		return { hasError: true };
	}

	componentDidCatch(error) {
		console.log(error);
	}

	render() {
		const { hasError } = this.state;
		const { classes, children } = this.props;
		if (hasError) {
			return (
				<Box className={classes.errorContainer}>
					<Typography component="h2" className={classes.title}>
						Sorry this page is broken
					</Typography>
				</Box>
			);
		}
		return children;
	}
}

export default styleWrapper(ErrorBoundary);

import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

// component
import ImageComponent from '@/components/widgets/image';

// material
import { Box, Button } from '@mui/material';

// actions
import {
	goToExternalRoute,
	goToRoute,
	isExternalUrl,
} from '@/store/actions/navigation';

// utils
import * as eventActions from '@/utils/analytics';

// styles
import useStyles from './style';

export default function GenericButton({ label, link, icon, ...props }) {
	const { classes } = useStyles(props);
	const dispatch = useDispatch();

	const trackEvent = (eventName, eventData = {}) => {
		eventActions.trackEvent(eventName, {
			...props.analyticsProps,
			eventLabel: label,
			eventAction: 'CLICKED',
			...eventData,
		});
	};

	const navigateToLink = () => {
		trackEvent('CTA_BUTTON_CLICKED', {
			eventCategory: 'NAVIGATION',
		});

		if (isExternalUrl(link)) {
			goToExternalRoute(link);
		} else {
			dispatch(goToRoute(link));
		}
	};

	const handleClick = () => {
		if (props.handleClick) props.handleClick();
		else if (link) navigateToLink();
	};

	return (
		<Button
			variant={props.variant}
			fullWidth={props.fullWidth}
			className={props.className || classes.button}
			onClick={handleClick}
			color={props.color || 'primary'}
			startIcon={
				icon ? (
					<Box className={icon.wrapperClassName || classes.iconWrap}>
						<ImageComponent
							title={label}
							objectFit="contain"
							layout="fill"
							src={icon.url}
							alt={icon.alt}
						/>
					</Box>
				) : null
			}
		>
			{label}
		</Button>
	);
}

GenericButton.propTypes = {
	label: PropTypes.string.isRequired,
	variant: PropTypes.string.isRequired,
	color: PropTypes.string,
	className: PropTypes.string,
	fullWidth: PropTypes.bool,
	handleClick: PropTypes.func,
	link: PropTypes.string,
	icon: PropTypes.object,
	analyticsProps: PropTypes.object,
};

GenericButton.defaultProps = {
	fullWidth: false,
	analyticsProps: {},
};

import { configureStore } from '@reduxjs/toolkit';
import {
	createRouterMiddleware,
	initialRouterState,
} from 'connected-next-router';
import { createWrapper } from 'next-redux-wrapper';
import Router from 'next/router';
import { format } from 'url';
// Master Reducer
import reducer from './reducers';
import { filterInitialState } from './reducers/filter';

/**
 * Initialize the store with reducer initial state and middleware
 */
const initStore = (initialState) => {
	return configureStore({
		reducer,
		preloadedState: initialState,
		middleware: (getDefaultMiddleware) => {
			return getDefaultMiddleware().concat(createRouterMiddleware());
		},
	});
};

/**
 * Make Store function creates the initial state for router and return store
 */
let store;
const makeStore = (context) => {
	const { asPath, pathname, query } = context.ctx || Router.router || {};
	let initialState = store && store.getState();
	if (asPath) {
		const url = format({ pathname, query });
		initialState = {
			...initialState,
			filter: filterInitialState,
			router: initialRouterState(url, asPath),
		};
	}
	store = initStore(initialState);

	return store;
};

/**
 * Wrapper to made store availability at SSR
 * debugging disabled on production
 */
export const wrapper = createWrapper(makeStore, {
	debug: process.env.NODE_ENV !== 'production',
});

export { store };

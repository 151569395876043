import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
	menuBarLeft: {
		cursor: 'pointer',
	},
	appBar: {
		backgroundColor: theme.palette.common.white,
		position: 'fixed',
	},
	toolbar: {
		paddingTop: theme.spacing(1),
		height: theme.spacing(9.5),
	},
	middleLineOverlay: {
		position: 'absolute',
		top: '50%',
		right: '10px',
		transform: 'translateY(-50%)',
		background: 'white',
		width: '5px',
		height: '5px',
	},
	contactContainer: {
		padding: theme.spacing(1),
		display: 'flex',
		alignItems: 'center',
		backgroundColor: theme.palette.common.backgroundGrey,
		color: theme.palette.common.black,
		fontWeight: theme.typography.fontWeightBold,
		cursor: 'pointer',
		justifyContent: 'flex-end',
	},
	topButton: {
		color: theme.palette.primary.main,
		fontWeight: theme.typography.fontWeightBold,
	},
}));

export default useStyles;

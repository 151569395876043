import constants from '@/constants';
import { levelAndType } from '@/utils/constants/program';
import { titleSizes } from '@/utils/constants/titleSizes';

export const defaultCoverImage =
	'https://production-azent-website-cms-uploads.s3.ap-south-1.amazonaws.com/Banner_USA_3_8498cb8874.jpg';

export const programTypes = ['degree', 'diploma', 'certificate', 'pathway'];

export const detailBannerSectionConfig = {
	id: 1,
	__component: 'university-page.university-detail-page-banner-section',
};

export const navigationSectionConfig = {
	id: 1,
	__component: 'shared.navigation-section',
};

export const overviewSectionConfig = {
	id: 1,
	__component: 'shared.overview-section',
	labelOnNavigation: 'Overview',
	title: 'Overview',
	titleFontSize: titleSizes.medium,
	shortDescriptionLetterCount: 500,
	shortDescriptionLetterCountMobile: 300,
};

export const getCostTableConfig = (levelFilter) => {
	let rowOrder = ['avg_tuition', 'living_cost'];

	if (levelFilter === levelAndType['PG Degree/Masters'])
		rowOrder.unshift('avg_tuition_for_pg');
	else if (levelFilter === levelAndType['UG Degree/Bachelors'])
		rowOrder.unshift('avg_tuition_for_ug');
	else rowOrder.unshift('avg_tuition_for_pg', 'avg_tuition_for_ug');

	return {
		id: 1,
		__component: 'generic-table-section',
		labelOnNavigation: 'Estimated Costs',
		highlightTitle: 'Estimated',
		title: 'Costs',
		titleFontSize: titleSizes.medium,
		headings: ['Monthly Expenses', 'Costs'],
		columnsAlignment: ['left', 'right'],
		rowOrder,
		rowHeadingLabels: {
			avg_tuition: 'Average Tution',
			avg_tuition_for_pg: 'Average Tution for PG',
			avg_tuition_for_pg_usd: 'Average Tution for PG USD',
			avg_tuition_for_ug: 'Average Tution for UG',
			avg_tuition_for_ug_usd: 'Average Tution for UG USD',
			avg_tuition_usd: 'Average Tution USD',
			currency: 'Currency',
			living_cost: 'Living Cost',
		},
		columnBorder: false,
		tableBorder: true,
	};
};

export const examAcceptedTableConfig = {
	id: 2,
	__component: 'generic-table-section',
	labelOnNavigation: 'Exams Required',
	columnBorder: false,
	tableBorder: true,
	highlightTitle: 'Exams',
	title: 'Required',
	titleFontSize: titleSizes.medium,
	columnsAlignment: ['center', 'center'],
	headings: ['Exams Accepted', 'Minimum Score Required'],
	rowOrder: [
		'ielts',
		'toefl',
		'sat',
		'gre',
		'gmat',
		'pte',
		'act',
		'melab',
		'toeic',
		'duolingo',
	],
	rowHeadingLabels: {
		ielts: 'IELTS',
		toefl: 'TOEFL',
		sat: 'SAT',
		gre: 'GRE',
		gmat: 'GMAT',
		pte: 'PTE',
		act: 'ACT',
		melab: 'MELAB',
		toeic: 'TOEIC',
		duolingo: 'DUOLINGO',
	},
};

export const getFeeEstimateTableConfig = (currency) => ({
	id: 3,
	__component: 'generic-table-section',
	labelOnNavigation: 'Fees Estimate',
	columnBorder: false,
	tableBorder: true,
	title: 'Fees Estimate',
	titleFontSize: titleSizes.medium,
	columnsAlignment: ['left', 'left', 'left'],
	headings: ['Course', 'Duration', currency ? `Fees (${currency})` : 'Fees'],
	link: '#',
	linkText: 'View All Courses',
});

export const programWithToggleSectionConfig = {
	id: 1,
	__component: 'univserity-data-list-section',
	labelOnNavigation: 'Top Programs',
	highlightTitle: 'Top',
	title: 'Programs',
	titleFontSize: titleSizes.medium,
	theme: 'programWithToggle',
};

export const contactAndLocationSectionConfig = {
	id: 1,
	__component: 'shared.contact-and-location-section',
	title: 'Location',
	titleFontSize: titleSizes.medium,
	labelOnNavigation: 'Location',
};

export const blogListingSectionConfig = {
	id: 1,
	__component: 'shared.listing-section',
	title: "There's a Guide for Everything",
	titleFontSize: titleSizes.medium,
	subTitle: 'Well researched, updated, and reliable insights for everyone',
	itemCount: '3',
	paginated: false,
	listType: 'other',
	labelOnNavigation: 'Blogs',
	link: `${constants.seo.sitemapBaseUrl}/expert-tips`,
};

export const socialPlatforms = [
	'facebook',
	'instagram',
	'linkedin',
	'twitter',
	'youtube',
	'website',
	'other',
];

export const topCounsellorSectionConfig = {
	id: 1,
	__component: 'shared.related-page-data-section',
	listType: 'counsellor',
	slider: true,
	itemCount: 6,
	useLocationFilter: true,
};

export const programWithFilterSectionConfig = {
	id: 1,
	__component: 'univserity-data-list-section',
	title: 'All Courses',
	titleFontSize: titleSizes.medium,
	theme: 'programWithFilter',
};

export const indexBannerSectionConfig = {
	id: 1,
	__component: 'university-page.university-page-banner-section',
};

export const universityWithFilterSectionConfig = {
	id: 1,
	__component: 'univserity-data-list-section',
	theme: 'unviersityWithFilter',
};

export const generalInformationDataKeys = [
	{ key: 'financial_aid', title: 'Financial Aid' },
	{ key: 'infrastructure', title: 'Infrastructure' },
	{ key: 'campus', title: 'Campus' },
	{ key: 'accommodation', title: 'Accommodation' },
	{ key: 'weather', title: 'Weather' },
	{ key: 'faculty', title: 'Faculty' },
	{ key: 'alumni', title: 'Alumni' },
	{ key: 'application_procedure', title: 'Application Procedure' },
	{ key: 'documents_required', title: 'Documents Required' },
];

export const generalInformationSectionConfig = {
	id: 1,
	__component: 'shared.categorized-module-section',
	highlightTitle: 'General',
	title: 'Information',
	titleFontSize: titleSizes.medium,
	labelOnNavigation: 'General Information',
};

export const compassCtaSectionConfig = {
	id: 1,
	__component: 'shared.login-cta-section',
	title: 'To find the best program, talk to our counselors today!',
	titleFontSize: titleSizes.medium,
	mobileTitle: 'To find the best program, talk to our counselors today! ',
	ctaButton: { label: 'Connect Now', link: constants.studentPlatformUrl },
};

export const compassExploreCourseCtaSectionConfig = {
	id: 2,
	__component: 'shared.login-cta-section',
	highlightTitle: 'Ace your IELTS with Azent! ',
	title:
		'We offer the the best in class IELTS courses that start at only Rs. 99!',
	titleFontSize: titleSizes.medium,
	mobileTitle:
		'We offer the the best in class IELTS courses that start at only Rs. 99!',
	ctaButton: {
		label: 'Explore Courses',
		link: `${constants.ieltsWebsiteUrl}/ielts-services`,
	},
};

export const compassExpenseCalculatorCtaSectionConfig = {
	id: 3,
	__component: 'shared.login-cta-section',
	title: 'Want to know the cost of studying at your dream destination?',
	titleFontSize: titleSizes.medium,
	subTitle:
		"Explore Azent's Expense Calculator tool to get an estimate on the cost of living and studying overseas.",
	mobileTitle: 'Want to know the cost of studying at your dream destination?',
	mobileSubTitle:
		"Explore Azent's Expense Calculator tool to get an estimate on the cost of living and studying overseas.",
	ctaButton: {
		label: 'Calculate Now!',
		link: `${constants.seo.sitemapBaseUrl}/expense-calculator`,
	},
};

export const appHighlightSectionConfig = {
	id: 1,
	__component: 'shared.download-app-highlight',
	title: 'Download the',
	titleFontSize: titleSizes.medium,
	highlightTitle: 'AZENT App',
	appIconLabel: 'Your Dream University is a Click Away!',
	titleImage: {
		url: 'https://production-azent-website-cms-uploads.s3.ap-south-1.amazonaws.com/app_store_1_c2b1ad717b.png',
		alternativeText: 'app store (1).png',
	},
	androidAppIcon: {
		id: 1,
		label: 'Android',
		link: 'https://play.google.com/store/apps/details?id=com.azentcompass',
		icon: {
			data: {
				attributes: {
					alternativeText: 'azent_app_play_store.svg',
					url: 'https://production-azent-website-cms-uploads.s3.ap-south-1.amazonaws.com/azent_app_play_store_ba41ddf897.svg',
				},
			},
		},
	},
	iosAppIcon: {
		id: 1,
		label: 'iOS',
		link: 'https://apps.apple.com/in/app/azent-compass/id1621494945',
		icon: {
			data: {
				attributes: {
					alternativeText: 'azent_app_store.svg',
					url: 'https://production-azent-website-cms-uploads.s3.ap-south-1.amazonaws.com/azent_app_store_8c9940c8d0.svg',
				},
			},
		},
	},
};

export const testimonialSectionConfig = {
	id: 1,
	__component: 'shared.testimonial-section',
	title: 'Success Stories Around The World',
	titleFontSize: titleSizes.medium,
	subtitle: 'You can be the next!',
	cardListTheme: 'theme_V1',
	labelOnNavigation: 'Testimonials',
	cardList: [
		{
			id: 1,
			name: 'Paahuni Khandelwal',
			universityName: 'University of Texas, Dallas ',
			courseName: 'MS - CS',
			testimonialText:
				'Selecting the right university is the single-most important factor for the success of your academic and career goals. Thanks to the personalized and actionable guidance, even at late hours during application filling, I got admitted to my dream university.',
			ratting: 5,
			image: {
				alternativeText: 'paahuni_jpg_85913cbe5f.webp',
				url: 'https://production-azent-website-cms-uploads.s3.ap-south-1.amazonaws.com/paahuni_jpg_85913cbe5f_9f955d21c7.webp',
			},
		},
		{
			id: 2,
			name: 'Rakesh A Choudhary',
			universityName: 'SUNY Buffalo, New York',
			courseName: 'MS - CS',
			testimonialText:
				'Application process is a very stressful period but Azent made it an easy one for me. It helped me get into one of the best university under my field. The mock visa interviews conducted by him no doubt helped me in boosting my confidence. Overall, I am very happy by the type of service Azent has offered me.',
			ratting: 5,
			image: {
				alternativeText: 'ezgif.jpg',
				url: 'https://production-azent-website-cms-uploads.s3.ap-south-1.amazonaws.com/ezgif_4244b7ad88.jpg',
			},
		},
		{
			id: 3,
			name: 'Priyanka Manikeri',
			universityName: 'George Brown College',
			courseName: 'School of Management',
			testimonialText:
				'My counsellor went the extra mile by breaking down every aspect of my application. I was always short on time so she even introduced me to a notary who could come to my office to pick up the documents and have it delivered directly to her!.',
			ratting: 5,
			image: {
				alternativeText: 'Priyanka_Manikeri_jpeg_50902e2255.webp',
				url: 'https://production-azent-website-cms-uploads.s3.ap-south-1.amazonaws.com/Priyanka_Manikeri_jpeg_50902e2255_35a4acc86d.webp',
			},
		},
	],
};

export const partnerTestimonialSectionConfig = {
	id: 1,
	__component: 'shared.testimonial-section',
	title: 'Partner Testimonials',
	titleFontSize: titleSizes.medium,
	subtitle: 'Connect with us today!',
	cardList: [
		{
			id: 1,
			name: 'Komal Agarwal',
			universityName: 'Gujarat, India',
			courseName: 'Gujarat, India',
			testimonialText:
				'Admission Box not only provides dedicated partner success managers, but their supremacy in management provides the fastest turnaround. Within 24 hours processes were completed.',
			ratting: 5,
		},
		{
			id: 2,
			name: 'Deep Hooda',
			universityName: 'Haryana, India',
			courseName: 'Haryana, India',
			testimonialText:
				"As a partner of Admission Box, it's truly an experience working with them. With uptight management and resources, I received more than 90% commission. Management of commission transparency is commendable.",
			ratting: 5,
		},
		{
			id: 3,
			name: 'Pravin Sehgal',
			universityName: 'Ahmedabad, India',
			courseName: 'Ahmedabad, India',
			testimonialText:
				'The Admission Box assists at each stage of the application journey, and their dedicated partner success manager helps in scaling management effectively. It is one of the best university admission providers so far in the industry.',
			ratting: 5,
		},
		{
			id: 4,
			name: 'Sanjay Singhania',
			universityName: 'Mumbai, India',
			courseName: 'Mumbai, India',
			testimonialText:
				'Admission Box provides exclusive training and constant support which helps in prompt responses for guiding students.',
			ratting: 5,
		},
	],
};

export const modalFormCtaSectionConfig = {
	id: 4,
	__component: 'shared.login-cta-section',
	title: 'Your Study Abroad Dream Is Just A Click Away',
	titleFontSize: titleSizes.medium,
	subTitle: "The only app you'll need for your entire journey",
	mobileTitle: 'Your Study Abroad Dream Is Just A Click Away',
	mobileSubTitle: "The only app you'll need for your entire journey",
	ctaButton: { label: 'Start Now', openFormModal: true },
};

export const partnerCtaSectionConfig = {
	id: 1,
	__component: 'shared.login-cta-section',
	title: 'Grow your business 10x with AdmissionBox',
	titleFontSize: titleSizes.medium,
	subTitle: 'Create applications within 30 mins',
	mobileTitle: 'Grow your business 10x with AdmissionBox',
	mobileSubTitle: 'Create applications within 30 mins',
	ctaButton: {
		label: 'Become A Partner',
		link: `${constants.businessPlatformUrl}/signup?partnerNew=true`,
	},
};

export const partnerCommissionCtaSectionConfig = {
	id: 2,
	__component: 'shared.login-cta-section',
	title: 'Upto 90% commissions on all successful admissions',
	titleFontSize: titleSizes.medium,
	subTitle: 'Trusted by Referral Partners around 100+ cities in India',
	mobileTitle: 'Upto 90% commissions on all successful admissions',
	mobileSubTitle: 'Trusted by Referral Partners around 100+ cities in India',
	ctaButton: {
		label: 'Become A Partner',
		link: `${constants.businessPlatformUrl}/signup?partnerNew=true`,
	},
};

export const partnerThirdCtaSectionConfig = {
	id: 3,
	__component: 'shared.login-cta-section',
	title: 'Earn upto ₹25,000 extra per Visa*',
	titleFontSize: titleSizes.medium,
	subTitle: 'Less than 24 hrs, fastest turnaround time in the industry',
	mobileTitle: 'Earn upto ₹25,000 extra per Visa*',
	mobileSubTitle: 'Less than 24 hrs, fastest turnaround time in the industry',
	ctaButton: {
		label: 'Become A Partner',
		link: `${constants.businessPlatformUrl}/signup?partnerNew=true`,
	},
};

export const textValues = {
	nonPartnerClientType: 'Non Partner',
	buttonLabelNonPartner: 'Get Started',
	buttonLabel: 'Apply Now',
};

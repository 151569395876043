import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	loading: true,
	data: null,
	error: null,
};

export const profile = createSlice({
	name: 'profile',
	initialState,
	reducers: {
		setStudentLoading: (state, { payload }) => {
			state.loading = payload;
		},
		setStudentData: (state, { payload }) => {
			state.data = payload;
		},
		resetStudentData: (state) => {
			state.loading = false;
			state.data = null;
			state.error = null;
		},
	},
});

export const { setStudentData, setStudentLoading, resetStudentData } =
	profile.actions;

export default profile;

import get from 'lodash/get';
import { useSelector } from 'react-redux';

// utils
import matchPath from '@/utils/url/matchPath';

export default function useStickyLoginWidget(resolvedUrl) {
	const userLoading = useSelector((state) => state.user.loading);
	const isMobile = useSelector((state) => state.common.isMobile);
	const user = useSelector((state) => state.user.data);

	const loginWidgetPagePaths = useSelector(
		(state) =>
			get(
				state.cmsGlobalData,
				'data.globalFloatingButtonConfig.loginWidgetPagePaths'
			) || []
	);

	const [stickyLoginWidgetConfig, setShowStickyLoginWidget] = React.useState({
		show: false,
		pathMatched: false,
	});

	const computeShowStickyLoginWidget = () => {
		if (typeof window === 'undefined' || !isMobile) return;

		const pathMatched = matchPath(resolvedUrl, loginWidgetPagePaths);
		setShowStickyLoginWidget({
			pathMatched,
			show: pathMatched && !(userLoading || (user && user.id)),
		});
	};

	React.useEffect(() => {
		computeShowStickyLoginWidget();
	}, [userLoading, user]);

	return [stickyLoginWidgetConfig, computeShowStickyLoginWidget];
}

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	isMobile: true,
	isDesktop: false,
	isTablet: false,
	paymentState: null,
	dynamicZoneSectionCount: null,
	dynamicZoneLoadedSectionIndex: 0,
};

export const common = createSlice({
	name: 'common',
	initialState,
	reducers: {
		setDevice: (state, { payload }) => {
			state.isMobile = payload.isMobile;
			state.isDesktop = payload.isDesktop;
			state.isTablet = payload.isTablet;
		},
		setPaymentState: (state, { payload }) => {
			state.paymentState = payload;
		},
		setDynamicZoneLoadedSectionIndex: (state) => {
			state.dynamicZoneLoadedSectionIndex =
				state.dynamicZoneSectionCount > state.dynamicZoneLoadedSectionIndex
					? state.dynamicZoneLoadedSectionIndex + 3
					: state.dynamicZoneLoadedSectionIndex;
		},
		setDynamicZoneSectionCount: (state, { payload }) => {
			state.dynamicZoneSectionCount = payload;
		},
	},
});

export default common;

import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
	footerActionTitle: {
		fontSize: '16px',
		backgroundColor: 'transparent',
		color: 'black',
		padding: theme.spacing(0, 1),
	},
	subActionLabel: {
		fontSize: '14px',
		padding: theme.spacing(0, 1),
		marginBottom: theme.spacing(1),
	},
}));

export default useStyles;

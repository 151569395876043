import { useSelector } from 'react-redux';

// constants
import { FOOTER_WRAPPER_ID } from '@/utils/constants/componentTypes';

// components
import Spinner from '@/components/widgets/spinner';

// mui
import Box from '@mui/material/Box';

import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
	spinnerWrapper: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		backgroundColor: theme.palette.common.white,
	},
}));

export default function useLazyLoad(WrappedComponent, index) {
	const dynamicZoneLoadedSectionIndex = useSelector(
		(state) => state.common.dynamicZoneLoadedSectionIndex
	);

	return index <= dynamicZoneLoadedSectionIndex ? WrappedComponent : null;
}

export const LazyFooter = ({ Component, lazyLoad, ...props }) => {
	const { classes } = useStyles();

	const isMobile = useSelector((state) => state.common.isMobile);
	const location = useSelector((state) => state.router.location);
	const dynamicZoneLoadedSectionIndex = useSelector(
		(state) => state.common.dynamicZoneLoadedSectionIndex
	);
	const dynamicZoneSectionCount = useSelector(
		(state) => state.common.dynamicZoneSectionCount
	);

	const [showFooter, setShowFooter] = React.useState(false);

	React.useEffect(() => {
		if (
			!lazyLoad ||
			(dynamicZoneSectionCount !== null &&
				dynamicZoneLoadedSectionIndex >= dynamicZoneSectionCount)
		) {
			setTimeout(() => setShowFooter(true), 0);
		}
	}, [dynamicZoneLoadedSectionIndex, dynamicZoneSectionCount, lazyLoad]);

	React.useEffect(() => {
		setShowFooter(false);
	}, [location]);

	return (
		<span id={FOOTER_WRAPPER_ID}>
			{showFooter || !isMobile ? (
				<Component {...props} />
			) : (
				<Box className={classes.spinnerWrapper}>
					<Spinner />
				</Box>
			)}
		</span>
	);
};

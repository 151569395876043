import getHeaders from '@/utils/get-headers';

export const handleCommonRequest = (instance, setHeaders = true) => {
	return instance.interceptors.request.use(
		(config) => {
			config.headers = setHeaders ? getHeaders() : {};
			return config;
		},
		(error) => {
			return Promise.reject(error);
		}
	);
};
export const handleCommonResponse = (instance) => {
	return instance.interceptors.response.use(
		(response) => response,
		(error) => Promise.reject(error)
	);
};

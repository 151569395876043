import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
	nested: {
		paddingLeft: theme.spacing(4),
		color: theme.palette.secondary.main,
	},
}));

export default useStyles;

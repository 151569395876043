import PropTypes from 'prop-types';
import React from 'react';

// material
import { KeyboardArrowDown } from '@mui/icons-material';
import {
	Avatar,
	Box,
	Button,
	Chip,
	ClickAwayListener,
	MenuItem,
	MenuList,
	Paper,
	Popper,
	Tooltip,
	Typography,
} from '@mui/material';

// style
import useStyles from './style';

export default function LoginMenuButton(props) {
	const { classes } = useStyles();
	const [anchorEl, setAnchorEl] = React.useState(null);

	const id = open ? 'simple-popper' : undefined;

	const handleClick = (event) => {
		setAnchorEl(anchorEl ? null : event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleListKeyDown = (event) => {
		if (event.key === 'Tab') {
			event.preventDefault();
			handleClose();
		}
	};

	let ButtonElement = (
		<Button
			aria-controls="simple-menu"
			aria-haspopup="true"
			aria-describedby={id}
			onClick={handleClick}
			className={classes[props.buttonClass]}
		>
			<Typography variant="h6">{props.label}</Typography>
		</Button>
	);

	if (props.buttonType == 'icon') {
		ButtonElement = (
			<Tooltip title={props.userName} aria-describedby={id}>
				<Chip
					label={<Box className={classes.chipLabel}>{props.userName}</Box>}
					onClick={handleClick}
					onDelete={handleClick}
					className={classes.iconButton}
					avatar={
						<Avatar className={classes.avatar}>{props.iconButtonLetter}</Avatar>
					}
					deleteIcon={<KeyboardArrowDown />}
					classes={{
						avatar: classes.avatar,
					}}
					variant="outlined"
					color="secondary"
				/>
			</Tooltip>
		);
	}

	return (
		<React.Fragment>
			{ButtonElement}
			<Popper
				id={id}
				open={Boolean(anchorEl)}
				anchorEl={anchorEl}
				disablePortal
				placement="bottom"
			>
				<Paper>
					<ClickAwayListener onClickAway={handleClose}>
						<MenuList
							autoFocusItem={Boolean(anchorEl)}
							id="menu-list-grow"
							onKeyDown={handleListKeyDown}
						>
							{props.subCategories.map((subCategory, index) => (
								<MenuItem
									onClick={() => {
										handleClose();
										subCategory.onClick();
									}}
									key={index}
								>
									{subCategory.label}
								</MenuItem>
							))}
						</MenuList>
					</ClickAwayListener>
				</Paper>
			</Popper>
		</React.Fragment>
	);
}

LoginMenuButton.propTypes = {
	subCategories: PropTypes.array.isRequired,
	label: PropTypes.string,
	buttonType: PropTypes.string,
	buttonClass: PropTypes.string,
	userName: PropTypes.string,
	iconButtonLetter: PropTypes.string,
};

LoginMenuButton.defaultProps = {
	label: '',
	buttonType: 'icon',
	buttonClass: '',
	userName: '',
	iconButtonLetter: '',
};

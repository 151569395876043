import constants from '@/constants';
import get from 'lodash/get';
import TagManager from 'react-gtm-module';

const getHash = function (str) {
	var hash = 0;
	for (var i = 0; i < str.length; i++) {
		hash = str.charCodeAt(i) + ((hash << 5) - hash);
	}
	hash = (hash & 0xffffffff).toString(16).toUpperCase();
	return hash;
};

let GoogleTagManager = function () {
	let name = 'GOOGLE_TAG_MANAGER';
	let isInitialised = false;

	let init = () => {
		if (
			typeof window !== 'undefined' &&
			get(constants, 'google_tag_manager_args.gtmId')
		) {
			TagManager.initialize(constants.google_tag_manager_args);
			isInitialised = true;
		}
	};
	let trackEvent = (eventName, eventProperties, additionalProperties) => {
		if (typeof window !== 'undefined' && isInitialised == true) {
			window.dataLayer.push({
				event: eventName,
				page: window.location.pathname,
				eventProperties,
				...(additionalProperties || {}),
			});
		}
	};

	let identify = (eventProperties) => {
		if (typeof window !== 'undefined' && isInitialised == true) {
			window.dataLayer.push({
				event: 'IDENTIFY',
				page: window.location.pathname,
				eventProperties,
				userId:
					get(eventProperties, 'registrant.email') &&
					getHash(get(eventProperties, 'registrant.email', '')),
			});
		}
	};

	let logPageView = () => {
		if (typeof window !== 'undefined' && isInitialised == true) {
			window.dataLayer.push({
				event: 'PAGE__VIEW',
				page: window.location.pathname,
			});
		}
	};

	return {
		name,
		isInitialised,
		logPageView,
		init,
		trackEvent,
		identify,
	};
};

export default GoogleTagManager();

import { makeStyles } from 'tss-react/mui';

const commonImageWrapStyles = {
	position: 'relative',
	overflow: 'hidden',
	margin: '0 auto',
};

const useStyles = makeStyles()((theme, props) => ({
	footerContainer: {
		justifyContent: 'center',
		opacity: 0.98,
		maxWidth: theme.breakpoints.values.xl,
		margin: '0 auto',
		padding: theme.spacing(7.5, 0),
		[theme.breakpoints.down('sm')]: {
			paddingTop: theme.spacing(4),
			paddingBottom: props.iconButtonPadding
				? theme.spacing(10)
				: theme.spacing(7.5),
		},
	},
	footerSectionOne: {
		width: '100%',
		padding: theme.spacing(0, 2),
		[theme.breakpoints.down('md')]: {
			marginBottom: theme.spacing(2),
		},
	},
	infoSectionContainer: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start',
		[theme.breakpoints.down('md')]: {
			alignItems: 'center',
		},
	},
	logoWrap: {
		width: '256px',
		height: '72px',
		position: 'relative',
		marginBottom: theme.spacing(2),
	},
	footerSectionTwo: {
		padding: theme.spacing(0, 2),
		justifyContent: 'flex-start',
		alignItems: 'flex-start',
		[theme.breakpoints.down('md')]: {
			padding: theme.spacing(0, 4),
		},
	},
	actionWrap: {
		paddingBottom: theme.spacing(2),
	},
	desktopOnly: {
		[theme.breakpoints.down('md')]: {
			display: 'none',
		},
	},
	iconButton: {
		borderRadius: '22px',
		padding: '8px 30px',
		fontSize: '16px',
	},
	sectionTwo: {
		display: 'flex',
		flexWrap: 'wrap',
		width: '100%',
		marginTop: '24px',
		gap: props.gap.desktop,
		[theme.breakpoints.between('md', 1025)]: {
			gap: props.gap.largeTablet,
		},
		[theme.breakpoints.down('md')]: {
			justifyContent: 'center',
			gap: props.gap.tablet,
			margin: 0,
		},
		[theme.breakpoints.down('sm')]: {
			gap: props.gap.mobile,
			margin: '0 0 24px 0',
		},
	},
	itemWrap: {
		flexGrow: 0,
		flexShrink: 1,
		width: props.desktop,
		[theme.breakpoints.between('md', 1025)]: {
			width: props.largeTablet,
		},
		[theme.breakpoints.down('md')]: {
			width: props.tablet,
		},
		[theme.breakpoints.down('sm')]: {
			width: props.mobile,
		},
	},
	circleImageWrap: {
		borderRadius: '50%',
		paddingTop: props.height,
		...commonImageWrapStyles,
	},

	rectangleImageWrap: {
		paddingTop: props.height,
		borderRadius: '10px',
		...commonImageWrapStyles,
	},
}));

export default useStyles;

import { checkCookieEnabled } from '../browserStorage/cookie';
import createAnalytics from './base';
import GoogleTagManager from './googleTagManager';

let analytics = createAnalytics();

if (
	process.env.APP_ENV === 'production' ||
	process.env.APP_ENV === 'ieltsProduction' ||
	process.env.APP_ENV === 'partnerProduction'
) {
	analytics.registerModule(GoogleTagManager);
}

if (checkCookieEnabled()) {
	analytics.init();
}

let trackEvent = analytics.eventsFunctions.trackEvent;
let identify = analytics.eventsFunctions.identify;
let logPageView = analytics.eventsFunctions.logPageView;
let init = analytics.init;

export { identify, init, logPageView, trackEvent };

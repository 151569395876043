import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment-mini';
import { useSelector } from 'react-redux';

// utils
import matchPath from '@/utils/url/matchPath';

const checkValidData = (
	{ ignorePagePaths = [], startTime, endTime },
	resolvedUrl
) => {
	let isValid = true;

	if (endTime) isValid = moment().isBefore(moment(endTime));

	if (isValid && startTime) isValid = moment().isSameOrAfter(moment(startTime));

	if (isValid && !isEmpty(ignorePagePaths))
		isValid = !matchPath(resolvedUrl, ignorePagePaths);

	return isValid;
};

export default function useAnnouncement(resolvedUrl) {
	const { config = [], ignorePagePaths = [] } = useSelector((state) =>
		get(state.cmsGlobalData, 'data.globalAnnouncementConfig', {})
	);

	const [announcementConfig, setShowAnnouncement] = React.useState({
		show: !matchPath(resolvedUrl, ignorePagePaths),
		data: config.filter((item) => checkValidData(item, resolvedUrl)),
	});

	const computeShowAnnouncement = (resolvedUrl) => {
		setShowAnnouncement({
			show: !matchPath(resolvedUrl, ignorePagePaths),
			data: config.filter((item) => checkValidData(item, resolvedUrl)),
		});
	};

	return [announcementConfig, computeShowAnnouncement];
}

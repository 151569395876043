import get from 'lodash/get';

// API
import { getCMSData } from '@/api';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	loading: false,
	data: null,
	error: null,
};

export const exitIntentModal = createSlice({
	name: 'exitIntentModal',
	initialState,
	reducers: {
		getExitIntentModalProps(state) {
			state.loading = true;
		},
		getExitIntentModalPropsSuccess: {
			reducer: (state, { payload }) => {
				state.loading = false;
				state.data = payload;
			},
			prepare: (data) => {
				let payload = null;

				if (data.length > 0)
					payload = {
						...get(data, '[0].attributes', {}),
						form: get(data, '[0].attributes.form.data.attributes', null),
					};

				return { payload };
			},
		},
		getExitIntentModalPropsFailure(state, { payload }) {
			state.loading = false;
			state.error = payload;
		},
	},
});

const {
	getExitIntentModalProps,
	getExitIntentModalPropsSuccess,
	getExitIntentModalPropsFailure,
} = exitIntentModal.actions;

function getExitIntentModalPropsAction(slug) {
	return (dispatch) => {
		dispatch(getExitIntentModalProps());
		return getCMSData('exit-intent-modals', {
			filters: { pageConfig: { path: { $in: slug } } },
			populate: ['pageConfig', 'form'],
		})
			.then((response) => {
				dispatch(
					getExitIntentModalPropsSuccess(get(response, 'data.data', []))
				);
			})
			.catch((err) => {
				dispatch(getExitIntentModalPropsFailure(err));
			});
	};
}

export default exitIntentModal;
export { getExitIntentModalPropsAction };

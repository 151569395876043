const checkLocalStorageEnabled = () => {
	try {
		if (
			typeof window !== 'undefined' &&
			typeof window.localStorage !== 'undefined'
		) {
			window.localStorage.setItem('local_storage_test', 'yes');
			if (window.localStorage.getItem('local_storage_test') === 'yes') {
				window.localStorage.removeItem('local_storage_test');
				return true;
			} else {
				return false;
			}
		} else {
			return false;
		}
	} catch {
		return false;
	}
};

export { checkLocalStorageEnabled };

import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

// constants
import { configOptions } from './constants';

// components
import FooterAction from '@/components/shared/footer/partials/actions';
import DesktopOnlySection from '@/components/shared/footer/partials/desktopOnlySection';
import GenericButton from '@/components/widgets/genericButton';
import ImageComponent from '@/components/widgets/image';

// mui
import { Box, Grid } from '@mui/material';

// actions
import { goToRoute } from '@/store/actions';

// styles
import useStyles from './styles';

export default function Footer({
	logo,
	actions,
	iconButton,
	imageShape,
	imageSize,
	images,
	...props
}) {
	imageSize = imageSize || 'small';
	imageShape = imageShape || 'rectangle';
	const isMobile = useSelector((state) => state.common.isMobile);
	const isDesktop = useSelector((state) => state.common.isDesktop);
	const config = configOptions[imageSize];

	const { classes } = useStyles({
		iconButtonPadding: iconButton,
		...config,
	});
	const dispatch = useDispatch();

	const onLogoClick = () => {
		dispatch(goToRoute('/'));
	};

	return (
		<Grid container className={classes.footerContainer}>
			<Grid item sm={12} md={4} className={classes.footerSectionOne}>
				<Box className={classes.infoSectionContainer}>
					<Box className={classes.logoWrap}>
						<ImageComponent
							src={get(logo, 'logo.data.attributes.url', '')}
							title={get(logo, 'title', '')}
							priority={true}
							layout="fill"
							onClick={onLogoClick}
							alt={get(logo, 'logo.data.attributes.alternativeText', '')}
						/>
					</Box>
					<Box className={classes.desktopOnly}>
						<DesktopOnlySection
							logoDescription={logo.description}
							footerAppIconLabel={props.footerAppIconLabel}
							socialIconActions={props.socialIconActions}
							androidAppIcon={props.androidAppIcon}
							iosAppIcon={props.iosAppIcon}
							iconButton={iconButton}
						/>
					</Box>
					{isDesktop && !isEmpty(images) && (
						<Box className={classes.sectionTwo}>
							{images.map((item, index) => (
								<Box key={index} className={classes.itemWrap}>
									<Box className={classes[`${imageShape}ImageWrap`]}>
										<ImageComponent
											src={item.url}
											alt={item.alternativeText}
											layout="fill"
											objectFit="contain"
										/>
									</Box>
								</Box>
							))}
						</Box>
					)}
				</Box>
			</Grid>
			<Grid item container sm={12} md={7} className={classes.footerSectionTwo}>
				{actions.map((action, index) => (
					<Grid item sm={4} xs={6} key={index} className={classes.actionWrap}>
						<FooterAction action={action} />
					</Grid>
				))}
			</Grid>
			{!isDesktop && !isEmpty(images) && (
				<Box className={classes.sectionTwo}>
					{images.map((item, index) => (
						<Box key={index} className={classes.itemWrap}>
							<Box className={classes[`${imageShape}ImageWrap`]}>
								<ImageComponent
									src={item.url}
									alt={item.alternativeText}
									layout="fill"
									objectFit="contain"
								/>
							</Box>
						</Box>
					))}
				</Box>
			)}
			{isMobile && iconButton && (
				<GenericButton
					variant="contained"
					color="success"
					className={classes.iconButton}
					label={iconButton.label}
					icon={{
						url: get(iconButton, 'icon.data.attributes.url', ''),
						alt:
							get(iconButton, 'icon.data.attributes.alternativeText') ||
							iconButton.label ||
							'',
					}}
					link={iconButton.link}
				/>
			)}
		</Grid>
	);
}

Footer.propTypes = {
	logo: PropTypes.object.isRequired,
	actions: PropTypes.array,
	iconButton: PropTypes.object,
};

Footer.defaultProps = {
	actions: [],
};

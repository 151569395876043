import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
	loginButtonLabel: {
		fontWeight: theme.typography.fontWeightBold,
		fontSize: theme.typography.body1.fontSize,
		[theme.breakpoints.down('sm')]: {
			fontWeight: theme.typography.fontWeightSemiBold,
			fontSize: theme.typography.subtitle2.fontSize,
		},
	},
	defaultButtonLabel: {
		textTransform: 'none',
		borderRadius: '70px',
	},
	invertedButtonLabel: {
		color: theme.palette.primary.main,
		textTransform: 'none',
	},
	outlinedButtonLabel: {
		color: theme.palette.primary.main,
		border: '1px solid ' + theme.palette.primary.main,
		textTransform: 'none',
	},
	defaultMenuListColor: {
		color: theme.palette.common.black,
	},
	invertedMenuListColor: {
		color: theme.palette.primary.main,
	},
	containedButtonLabel: {
		backgroundColor: theme.palette.secondary.main,
		border: '1px solid ' + theme.palette.secondary.main,
		textTransform: 'none',
		color: theme.palette.common.white,
		borderRadius: '25px',
		padding: theme.spacing(0, 2),
		[theme.breakpoints.down('md')]: {
			marginLeft: theme.spacing(3),
		},
	},
}));

export default useStyles;

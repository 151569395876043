import get from 'lodash/get';

export const deserializeProfile = (studentData) => {
	if (!studentData) return null;

	return {
		firstName: studentData.first_name,
		lastName: studentData.last_name,
		is_tests_opted: studentData.is_tests_opted,
		mobile: studentData.mobile,
		email: studentData.email,
		city: get(studentData, 'address.city'),
		desiredProfile: studentData.desired_profile,
		testScores: studentData.test_scores,
		educationHistory: studentData.education_history,
	};
};

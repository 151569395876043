const { pageTypes } = require('../utils/constants/pageTypes');
const getPlatform = require('../utils/getPlatform');

module.exports = {
	platform: getPlatform(),
	appName: 'AzentWebsite',
	authKey: 'AUTH_TOKEN',
	googleMapsApiUrl:
		'https://www.google.com/maps/embed/v1/place?key=AIzaSyCUfFTasHUSPxcz_Hy8an09z-EfPTH1CpA&',
	seo: {
		sitemapBaseUrl: '',
		policies: [{ userAgent: '*', disallow: '/' }],
		index: false,
		ampBaseUrl: '',
		pathExcludedFromSiteMap: [
			'/server-sitemap*',
			'/sitemap.xml',
			'/j',
			'/record-finder',
			'/record-testimonial',
			'/thank-you*',
			'/kiosk*',
		],
		pathPriorityMap: { '/': '1.0', '/home-page': '1.0', '/expert-tips': '0.9' },
		noIndexPages: new Set([pageTypes.thankYou]),
	},
	whatsAppChatBotUrl: 'https://wa.me/+918097506699?text=Hi',
	useStructuredData: true,
	webStoryDomain: 'https://www.azent.com',
	awsTestimonialsConfig: {
		awsKey: 'AKIA4KCCKZYLKVL63MFF',
		bucket: 'azent-testimonials',
		region: 'ap-south-1',
		s3BucketBaseUrl: 'https://azent-testimonials.s3.ap-south-1.amazonaws.com',
	},
};

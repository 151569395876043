import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
	menuList: {
		width: theme.spacing(30),
	},
	menuItemLeftBorder: {
		borderLeft: '4px solid ' + theme.palette.primary.main,
	},
	defaultMenuListColor: {
		color: theme.palette.common.black,
	},
	invertedMenuListColor: {
		color: theme.palette.primary.main,
	},
	tag: {
		fontSize: '0.65rem',
		borderRadius: '5px',
		[theme.breakpoints.down('md')]: {
			fontSize: '0.55rem',
		},
		height: theme.spacing(2.5),
		padding: theme.spacing(0, 0.25),
		margin: theme.spacing(0, 0, 0, -1),
	},
}));

export default useStyles;
